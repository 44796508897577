import { Box, Checkbox, Menu, MenuItem, styled } from '@mui/material'
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import {TreeItem, SimpleTreeView } from '@mui/x-tree-view'

import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import TreeOptionsPanel from './TreeOptionsPanel'

const TreeViewStyled = styled(SimpleTreeView)(({ theme }) => ({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  color: theme.customPalette.text,
}))

export interface ITreeNode {
  nodeId: string
  label: string
  otherProps?: {
    [key: string]: string
  }
  children?: ITreeNode[]
  parent?: ITreeNode
}

interface INodeActions {
  setExpanded: React.Dispatch<React.SetStateAction<string[]>>
  checked: string[]
  setChecked: React.Dispatch<React.SetStateAction<string[]>>
  indeterminated: string[]
  setIndeterminated: React.Dispatch<React.SetStateAction<string[]>>
  handleOpen: (x: number, y: number, childrenIds: string[]) => void
  search: string
}

const getChildrenIds = (children: ITreeNode[]) => {
  let result: string[] = []

  children.forEach(child => {
    result.push(child.nodeId)

    if (child.children) {
      const childrenIds = getChildrenIds(child.children)
      result = result.concat(childrenIds)
    }
  })

  return result
}

const getExapndSearchIds = (search: string, nodes: ITreeNode[]) => {
  let result: string[] = []

  nodes.forEach(node => {
    let childrenIds: string[] = []
    if (node.children) {
      childrenIds = getExapndSearchIds(search, node.children)
      result = result.concat(childrenIds)
    }

    if (
      node.label.toLowerCase().includes(search.toLocaleLowerCase()) ||
      childrenIds.length
    )
      result.push(node.nodeId)
  })

  return result
}

const nodesToOpen = (nodes: ITreeNode[], depth: number = 0) => {
  let result: string[] = []
  if (depth === 0) return result

  nodes.forEach(node => {
    result.push(node.nodeId)

    if (node.children) {
      result = result.concat(nodesToOpen(node.children, depth - 1))
    }
  })

  return result
}

const TreeNode = ({
  nodeId,
  label,
  children,
  setExpanded,
  checked,
  setChecked,
  parent,
  indeterminated,
  setIndeterminated,
  handleOpen,
  search,
}: ITreeNode & INodeActions) => {
  const labelIcon = children?.length
    ? FolderOpenIcon
    : InsertDriveFileOutlinedIcon

  const handleExpande = () => {
    setExpanded(previous => {
      return [...previous, nodeId]
    })
  }

  const handleCollapse = () => {
    setExpanded(previous => {
      const index = previous.findIndex(val => val === nodeId);
      if (index !== -1) {
        return previous.filter(val => val !== nodeId);
      } else {
        return [...previous, nodeId];
      }
    });
  }


  const handleChecked = (_: any, value: boolean) => {
    setChecked(previous => {
      let updatedChecked = previous.filter(val => val !== nodeId)
      let childrenIds = getChildrenIds(children || [])

      if (value) {
        updatedChecked = [...updatedChecked, nodeId, ...childrenIds]
      } else {
        updatedChecked = updatedChecked.filter(val => !childrenIds.includes(val))
      }

      return updatedChecked
    })
  }

  let displayValue = 'flex'
  let filter = ''
  if (children?.length) {
    filter = label.toLowerCase().includes(search.toLowerCase())
      ? filter
      : 'brightness(0.25)'
  } else {
    displayValue = label.toLowerCase().includes(search.toLowerCase())
      ? displayValue
      : 'none'
  }

  return (
    <TreeItem
      itemId={nodeId}
      label={
        <Box
          onContextMenu={ev => {
            ev.preventDefault()
            if (children?.length) {
              handleOpen(ev.clientX, ev.clientY, getChildrenIds(children))
            }
          }}
          sx={{
            display: displayValue,
            alignItems: 'center',
            p: 0.5,
            px: 0,
            filter: filter,
          }}
        >
          <Checkbox
            sx={{ padding: '0 .5rem 0 0' }}
            checked={checked.includes(nodeId)}
            indeterminate={
              indeterminated.includes(nodeId) && !checked.includes(nodeId)
            }
            onClick={e => e.stopPropagation()}
            onChange={handleChecked}
          />
          <Box component={labelIcon} sx={{ mr: '0.5rem' }} />
          <div>{label}</div>
        </Box>
      }
      sx={{
        width: 'calc(100% - 1rem)',
      }}
      onClick={handleCollapse}
    >
      {children?.map((child, i) => (
        <TreeNode
          key={i}
          {...child}
          setExpanded={setExpanded}
          checked={checked}
          setChecked={setChecked}
          indeterminated={indeterminated}
          setIndeterminated={setIndeterminated}
          handleOpen={handleOpen}
          search={search}
        />
      ))}
    </TreeItem>
  )
}

interface ITree {
  nodes: ITreeNode[]
  checked: string[]
  setChecked: Dispatch<SetStateAction<string[]>>
  expanded: string[]
  setExpanded: Dispatch<SetStateAction<string[]>>
  indeterminated: string[]
  setIndeterminated: Dispatch<SetStateAction<string[]>>
  nodeToOpen?: number
  showOptionsPanel?: boolean
}

const Tree = ({
  nodes,
  checked,
  setChecked,
  expanded,
  setExpanded,
  indeterminated,
  setIndeterminated,
  nodeToOpen,
  showOptionsPanel,
}: ITree) => {
  const [MenuProps, setMenuProps] = useState<{
    open: boolean
    mouseX: number
    mouseY: number
    childrenIds: string[]
  }>({
    open: false,
    mouseX: 0,
    mouseY: 0,
    childrenIds: [],
  })
  const [Search, setSearch] = useState('')

  useEffect(() => {
    if (Search !== '') {
      let ids = getExapndSearchIds(Search, nodes)
      setExpanded(ids)
    } else if (nodeToOpen) {
      const expandedNodes = nodesToOpen(nodes, nodeToOpen)
      setExpanded(expandedNodes)
    }
  }, [Search, nodes, nodeToOpen, setExpanded])

  const handleClose = () => {
    setMenuProps(prev => ({ ...prev, open: false }))
  }

  const handleOpen = (x: number, y: number, childrenIds: string[]) =>
    setMenuProps({
      open: true,
      mouseX: x,
      mouseY: y,
      childrenIds,
    })

  const handleDeselectAll = () => {
    setChecked([])
  }

  const handleSelectAll = () => {
    const ids = getChildrenIds(nodes)
    setChecked(ids)
  }

  return (
    <TreeViewStyled expandedItems={expanded}>
      {showOptionsPanel && (
        <TreeOptionsPanel
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          handleSearch={val => setSearch(val)}
        />
      )}
      {nodes && nodes?.map((node, i) => (
        <TreeNode
          key={i}
          {...node}
          setExpanded={setExpanded}
          checked={checked}
          setChecked={setChecked}
          indeterminated={indeterminated}
          setIndeterminated={setIndeterminated}
          handleOpen={handleOpen}
          search={Search}
        />
      ))}
      <Menu
        open={MenuProps.open}
        anchorReference='anchorPosition'
        anchorPosition={{
          top: MenuProps.mouseY,
          left: MenuProps.mouseX,
        }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            setChecked(prev => {
              const newIds = MenuProps.childrenIds.filter(
                child => !prev.includes(child)
              )
              return [...prev, ...newIds]
            })
          }}
        >
          Select all children
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            setChecked(prev => {
              const newIds = prev.filter(
                id => !MenuProps.childrenIds.includes(id)
              )

              return newIds
            })
          }}
        >
          Deselect all children
        </MenuItem>
      </Menu>
    </TreeViewStyled>
  )
}

export default Tree
