import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

interface IGraphEditorSelect {
  items: {
    value: string
    text: string
  }[]
  label: string
  value: string
  handleChange: (arg0: string) => void
}

const GraphEditorSelect = ({
  items,
  label,
  value,
  handleChange,
}: IGraphEditorSelect) => {
  return (
    <FormControl
      variant='standard'
      sx={{ ml: 'auto', maxWidth: '125px' }}
      size='small'
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={({ target: { value } }) => handleChange(value)}
      >
        {items?.map(item => (
          <MenuItem key={item?.value} value={item?.value}>
            {item?.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default GraphEditorSelect
