import { EChartOption } from 'echarts'

export const getDefaultPie = (data: {value:number, name: string}[], colors: string[]) =>({
    tooltip: {
        trigger: 'item',
      },
      legend:{
        textStyle: {
            color:'#FBF9FF',
            fontWeight:'bold'
        }
      },
      series: [
        {
          name: 'Type',
          type: 'pie',
          radius: '65%',
          center: ['50%', '50%'],
          selectedMode: 'single',
          color: colors,
          data: data,
          label:{
            color:'#FBF9FF'
          }
        }
        ],
        emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }}
} as any as EChartOption)