import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'

import { Login } from './Templates/Login'
import Layout from './Components/Layout'
import {
	NewProjectData,
	ProjectsData,
	AnalysisData,
	OverviewData,
	ProjectSubHeader,
	DependencyGraphData,
	AssessmentData,
} from './Pages'
import { useAuth } from './OIDC/AuthContext'
import LoginCallback from './OIDC/LoginCallback'
import LogoutCallback from './OIDC/LogoutCallback'
import SilentRenew from './OIDC/SilentRenew'
import SmellGraphData from "./Pages/Project/SmellGraphData";

const ProtectedRoute = () => {
	const { isAuthenticated, signinRedirect } = useAuth()
	if (!isAuthenticated()) {
		signinRedirect()
		return <div />
	}

	return (
		<Layout>
			<Outlet />
		</Layout>
	)
}

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<ProtectedRoute />}>
					<Route path='' element={<ProjectsData />} />
					<Route path='project/new' element={<NewProjectData />} />
					<Route path='project/new/:projectId/*' element={<AnalysisData />} />
					<Route
						path='project/edit/:projectId/*'
						element={<AnalysisData exist />}
					/>
					<Route path='project/:projectId' element={<ProjectSubHeader />}>
						<Route path='overview/:versionId' element={<OverviewData />} />
						<Route path='graph/:versionId' element={<DependencyGraphData />} />
						<Route path='smells/:versionId' element={<AssessmentData />} />
						<Route path='smells/:versionId/selectedsmell/:selectedNodeId' element={<SmellGraphData />}></Route>
					</Route>
				</Route>
				<Route path='/login' element={<Login />} />
				<Route path='/signin-oidc' element={<LoginCallback />} />
				<Route path='/logout/callback' element={<LogoutCallback />} />
				<Route path='/silentrenew' element={<SilentRenew />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
