import Box from '@material-ui/core/Box'
import {Stack} from '@mui/material'
import {Typography} from '@mui/material'
import { TrendDataPoint } from 'Model/arcan-types'

interface IGrid {
    GridOverview:{
		content?:any
		box?:{
			title: string
			data: any
		}
		box1?:{
			title: string
			data: any
		}
		box2?:{
			title: string
			data: any
		}
		box3?:{
			chart?: {
				title: string
				data:any
			}
		}
		
    }
}

const TrendDataList =({data}:{
	data?: TrendDataPoint
}) => {
	data =
		data ??
		({ value: 0, trendPercentage: 0, trend: 'NEUTRAL' } as TrendDataPoint)
	return (
		<Typography fontSize={18} fontWeight={'bold'}>
			{data.value ?? 0}
		</Typography>
	)
}

const OverviewGrid =({GridOverview}: IGrid) => {
	return(
		<Box className='gridoverview'>
			<Stack direction="row" spacing={2}>
				<Box className='cardoverviewsmall'>
						{GridOverview.box?(
							<>
							<Typography variant='caption' component={'div'} color={'#FF3399'} textAlign={'center'} fontSize={20}>{GridOverview.box.title}</Typography>
								<TrendDataList data={GridOverview.box?.data} />
							</>
						):null}
				</Box>
				<Box className='cardoverviewsmall'>
						{GridOverview.box1?(
							<>
							<Typography variant='caption' component={'div'} color={'#FF3399'} textAlign={'center'} fontSize={20}>{GridOverview.box1.title}</Typography>
								<TrendDataList data={GridOverview.box1?.data} />
							</>
						):null}
				</Box>
				<Box className='cardoverviewsmall'>
						{GridOverview.box2?(
							<>
							<Typography variant='caption' component={'div'} color={'#FF3399'} textAlign={'center'} fontSize={20}>{GridOverview.box2.title}</Typography>
								<TrendDataList data={GridOverview.box2?.data} />
							</>
						):null}
				</Box>
			</Stack>
		</Box>
	)
}

export default OverviewGrid