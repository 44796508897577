import {CardContent,Box, CircularProgress} from '@mui/material'
import { Typography } from '@mui/material'

interface ICardMetrics {
    cardMetrics:{
		data?:any
        type?:{
            icon?:{
                src:any
                alt:any
            }
            text:string
			data?:any
    }
    }
    
}
function CircularProgressMetrics (props:{value:number}) {
	let percentage = props.value

	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
		  <CircularProgress
			variant='determinate'
			value={percentage}
			color='secondary' 
			size={70}
		  />
		  <Box
			sx={{
			  top: 0,
			  left: 0,
			  bottom: 0,
			  right: 0,
			  position: 'absolute',
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			}}
		  >
			<Typography variant='caption' component='div' style={{ color: '#FBF9FF' }} fontSize={16}>
			  {percentage}% 
			</Typography>
		  </Box>
		</Box>
	  )
}


const CardMetrics=({cardMetrics}:ICardMetrics)=>{
    return(
        <Box sx={{marginBottom:'10px'}}>
            <CardContent sx={{display:'flex', borderRadius:'16px'}}>
                {cardMetrics.data?(
                    <Box sx={{position: 'relative', display: 'inline-flex', marginRight:'20px'}}>
						<CircularProgressMetrics value={cardMetrics.data} /> 
                    </Box>
                ):null}
                {cardMetrics.type?(
                    <div style={{display:'flex'}}>
                        <img className='img' style={{marginRight:'20px'}}src={cardMetrics.type.icon?.src} alt={cardMetrics.type.icon?.alt}/>
                        <p style={{fontWeight:'bold', marginTop:'20px'}}>{cardMetrics.type.text}</p>         
                    </div>
                ):null}
            </CardContent>
        </Box>
    )
}

export default CardMetrics;
