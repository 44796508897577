import { Version } from 'Model/arcan-types'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { SubHeader } from 'Components/Header'
import WithLoading from 'Components/HOC/WithLoading'
import { SimpleListMenu } from 'Components/Menu'
import { useGetProjectLazy } from 'Queries/Projects'
import Icon from '@mui/material'


import OverviewIcon from 'img/icons/Icon/Overview.png'
import AssessmentIcon from 'img/icons/Icon/Assessment.png'
import ComprehensionIcon from 'img/icons/Icon/comprehension.png'
import InsightIcon from 'img/icons/insights.png'

const SubHeaderWithLoading = WithLoading(SubHeader)

const getSortedVersions = (versions: Version[]) =>
	[...versions].sort((a, b) => {
		return new Date(a.versionDate) > new Date(b.versionDate) ? -1 : 1
	})

const ProjectSubHeader = () => {
	const [SelectedVersion, setSelectedVersion] = useState<Version>()

	const { projectId, versionId } =
		useParams<{ projectId: string; versionId: string }>()
	const [getProject, { loading, data }] = useGetProjectLazy()
	const { pathname } = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if (projectId) {
			getProject({
				variables: {
					projectId: parseInt(projectId),
				},
			})
		}
	}, [getProject, projectId])

	useEffect(() => {
		if (data?.projectById) {
			const { versions, lastAnalysedVersion } = data.projectById

			if (versionId && versionId !== '') {
				setSelectedVersion(versions.find((v) => v.versionId === versionId))
			} else if (SelectedVersion == null) {
				setSelectedVersion(
					versions.find(
						(v) => v.versionIndex === lastAnalysedVersion?.versionIndex
					)
				)
			}
		}
	}, [SelectedVersion, data, versionId])

	let options: {
		label: string
		checked: boolean
	}[] = []
	let selected = 0
	if (SelectedVersion && data?.projectById) {
		const { versions } = data.projectById
		const sortedVersions = getSortedVersions(versions)
		selected = sortedVersions.findIndex((v) => v === SelectedVersion)

		options = sortedVersions.map((v) => ({
			label: `${v.versionIndex}-${v.shortenedVersionId} - ${new Date(
				v.versionDate
			).toLocaleDateString()} ${v.versionTag ? `(${v.versionTag})` : ''}`,
			checked: v.isSuccessfullyAnalysed,
		}))
	}

	const hideInsightBtn: boolean = ((window._env_.REACT_APP_HIDE_INSIGHTS || process.env.REACT_APP_HIDE_INSIGHTS) ?? 'false') === "true"
	const insightUrl: string = ((window._env_.REACT_APP_INSIGHTS_URL || process.env.REACT_APP_INSIGHTS_URL) ?? '') + `?project=${projectId}`

	const buttons: {
		text: string
		variant: 'text' | 'contained' | 'outlined'
		hidden: boolean
		disabled?: boolean
		onClick?: () => void
	}[] = [
		{
			
			text: 'OVERVIEW',
			variant: 'text',
			hidden: false,
			disabled: pathname.includes('overview'),
			onClick: () => {
				navigate(`overview/${SelectedVersion?.versionId ?? ''}`, {
					replace: false,
				})
			},
		},
		{	
			
			text: 'INSIGHTS',
			variant: 'text',
			hidden: hideInsightBtn,
			disabled: pathname.includes('insights'),
			onClick: () => {
				window.location.href = insightUrl
			},
		},
		{	
			
			text: 'ASSESSMENT',
			variant: 'text',
			hidden: false,
			disabled: pathname.includes('smells'),
			onClick: () => {
				navigate(`smells/${SelectedVersion?.versionId ?? ''}`, {
					replace: false,
				})
			},
		},
		{	
			
			text: 'COMPREHENSION',
			variant: 'text',
			hidden: false,
			disabled: pathname.includes('graph'),
			onClick: () => {
				navigate(`graph/${SelectedVersion?.versionId ?? ''}`, { replace: false })
			},
		},
	]

	return (
		<>	
			<SubHeaderWithLoading
				loading={loading}
				title={
					<SimpleListMenu
						title={data?.projectById?.name ?? ''}
						selected={selected}
						options={options}
						menuclass='clean'
						onChange={(index) => {
							const sortedVersions = getSortedVersions(
								data?.projectById.versions ?? []
							)
							const version = sortedVersions[index]
							setSelectedVersion(version)
							const lastUrlPart = pathname.lastIndexOf('/')
							const baseUrl = pathname.substring(0, lastUrlPart)
							navigate(`${baseUrl}/${version?.versionId ?? ''}`, {
								replace: true,
							})
						}}
					/>
				}
				buttons={buttons}
			/>
			<Outlet />
		</>
	)
}

export default ProjectSubHeader
