import Chart from 'Components/Chart/Chart'
import { 
	CircularProgress,
	CardContent,
	Typography,
	Box,
	ListItem,
	ListItemIcon,
	ListItemText,
	styled
 } from '@mui/material'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { TrendDataPoint } from 'Model/arcan-types'

interface ICardOverview {
    projectCard:{
        title?: string
        value?: TrendDataPoint
		progress?:{
			data?: any
			rating?:{
				data?:any
			}
		}
        um?: any
		list?:{
			type?:{
				name:string
				icon?:
				{
				src:any
				alt:any
				}
				data:any
			}
			type1?:{
				name:string
				icon?:
				{
				src:any
				alt:any
				}
				data:any
			}
			type2?:{
				name:string
				icon?:
				{
				src:any
				alt:any
				}
				data:any
			}
			
		}

        chart?:{
            data?:any
        }
    }
}

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 16px;
  max-width: 100%;
  overflow: hidden;
`;

const TrendDataSmell = ({
	data,
	um,
}: {
	data?: TrendDataPoint
	um: string
}) => {
	data =
		data ??
		({ value: 0, trendPercentage: 0, trend: 'NEUTRAL' } as TrendDataPoint)

	const defaultIconProps = {
		margin: 'auto 0 auto .25rem',
	}
	const icon =
		data.trendPercentage > 0 ? (
			<ArrowDropUpIcon sx={defaultIconProps} />
		) : data.trendPercentage < 0 ? (
			<ArrowDropDownIcon sx={defaultIconProps} />
		) : (
			<DragHandleIcon sx={{ ...defaultIconProps, fontSize: 24 }} />
		)

	const color =
		data?.trend === 'POSITIVE'
			? 'green'
			: data?.trend === 'NEGATIVE'
			? 'red'
			: 'silver'
	return (
		<>
			<Typography fontSize={120} textAlign={'center'} alignContent={'center'}>
				{data.value ?? 0}
				{um}
			</Typography>
			<Typography
				fontSize={24}
				color={color}
				sx={{ float: 'right', marginTop:'1rem'}}
			>
				{data.trendPercentage}%{icon}
			</Typography>
		</>
	)
}

const TrendDataList =({data}:{
	data?: TrendDataPoint
}) => {
	data =
		data ??
		({ value: 0, trendPercentage: 0, trend: 'NEUTRAL' } as TrendDataPoint)
	return (
		<Typography fontSize={18}>
			{data.value ?? 0}
		</Typography>
	)
}

function RatingScore(props: { value: number }) {
	let rating = '';
	let color = '';
  
	if (props.value < 0.2) {
	  rating = 'F';
	  color = 'red';
	} else if (props.value >= 0.2 && props.value < 0.45) {
	  rating = 'D';
	  color = '#FBF9FF';
	} else if (props.value === 0.50) {
	  rating = 'C';
	  color = 'orange';
	} else if (props.value > 0.5 && props.value <= 0.75) {
	  rating = 'C';
	  color = 'orange';
	} else if (props.value > 0.75 && props.value <= 0.85) {
	  rating = 'B';
	  color = 'yellow';
	} else if (props.value > 0.85) {
	  rating = 'A';
	  color = 'green';
	}
  
	return (
	  <Box sx={{ position: 'relative' }}>
		<Typography variant='h2'fontSize={30} style={{ color }}>
		  {rating}
		</Typography>
	  </Box>
	);
  }

function CircularProgressScore (props: { value: number }){
	let percentage = props.value * 100

	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
		  <CircularProgress
			variant='determinate'
			value={percentage}
			color='secondary' 
			size={230}
		  />
		  <Box
			sx={{
			  top: 0,
			  left: 0,
			  bottom: 0,
			  right: 0,
			  position: 'absolute',
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			}}
		  >
			<Typography variant='caption' component='div' style={{ color: '#FBF9FF' }} fontSize={40}>
			  {percentage}% {/* Visualizza il valore come percentuale arrotondata a 3 decimali */}
			</Typography>
		  </Box>
		</Box>
	  )
}
const CardOverview =({projectCard}: ICardOverview) =>{
	return(
		<Box>
			<StyledCardContent className='cardoverview'>
				{projectCard.title?(
					<h2 style={{color:'#FF3399', margin: '1rem 0 0 0'}}>{projectCard.title}{''}</h2>
				):null}
					{projectCard.value ?(
						<TrendDataSmell data={projectCard.value} um={projectCard.um} />
					):null}
					{projectCard.progress?(
						<Box sx={{position: 'relative', display: 'flex', alignItems:'center', justifyContent:'center', marginTop:'1rem'}}>
							<CircularProgressScore value={projectCard.progress.data} />
							{projectCard.progress.rating?(
								<div style={{position: 'absolute', display: 'flex', float:'right', bottom: '-25%'}}>
									<Typography variant='h2' display={'inline-flex'} fontSize={30} color={'#ff3399'} marginRight={'10px'}> Rating:</Typography>
									<RatingScore value={projectCard.progress.rating.data} />
								</div>
							):null}
						</Box>
					):null}

					{projectCard.list?(
							<>
								<ListItem sx={{marginTop:'60px', marginBottom:'40px'}}>
									{
										projectCard.list.type ?(
											<ListItemIcon>
												<img src={projectCard.list.type.icon?.src} alt={projectCard.list.type.icon?.alt} />
											</ListItemIcon>
										):null
									}
										<ListItemText sx={{marginRight:'55%'}}>
											{projectCard.list.type?.name}
										</ListItemText>
									<TrendDataList data={projectCard.list.type?.data} />
									</ListItem>

								<ListItem sx={{marginBottom:'40px'}} >
									{
										projectCard.list.type1 ?(
											<ListItemIcon>
												<img src={projectCard.list.type1.icon?.src} alt={projectCard.list.type1.icon?.alt} />
											</ListItemIcon>
										):null
									}
									<ListItemText sx={{marginRight:'65%'}}>
										{projectCard.list.type1?.name}
									</ListItemText>
									<TrendDataList data={projectCard.list.type1?.data}/>
								</ListItem>
								<ListItem sx={{marginBottom:'40px'}}>
									{
										projectCard.list.type2 ?(
											<ListItemIcon>
												<img src={projectCard.list.type2.icon?.src} alt={projectCard.list.type2.icon?.alt} />
											</ListItemIcon>
										):null
									}
									<ListItemText sx={{marginRight:'45%'}}>
										{projectCard.list.type2?.name}
									</ListItemText>
									<TrendDataList data={projectCard.list.type2?.data}/>
								</ListItem>
								
							</>
					):null}
					{projectCard.chart?(
						//<div style={{ width: '100%', height:'100%' }}>
							<Chart options={projectCard.chart.data} />
						//</div>
					):null}
			</StyledCardContent>
		</Box>
	)
}
export default CardOverview;

