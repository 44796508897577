import { CircularProgress, Box, Typography, Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import MUIDataTable, {
	DisplayData,
	MUIDataTableColumnDef,
	MUIDataTableOptions,
	FilterType, MUIDataTableColumn, MUIDataTableState,
} from 'mui-datatables'
import Cyclic from '../../img/icons/Cyclic.svg'
import HubLike from '../../img/icons/HubLike.svg'
import God from '../../img/icons/God.svg'
import Unstable from '../../img/icons/Unstable.svg'
import { getSmellsGauge } from '../../lib/gauge'
import Chart from '../Chart/Chart'
import { ArchitecturalSmell } from 'Model/arcan-types'
import {Dispatch, SetStateAction, useEffect} from 'react'
import { useReactiveVar } from '@apollo/client';
import { TableState, tableStateVar } from '../../apollo/states';

interface ISmell {
	id: number
	fullTypeName: string
	level: string
	size: number
	affectedComponents: string
	tdi: number
	severity: number
	fanIn: number
	fanOut: number
	affectedCentre: string
	detectionRule: string
	detectionValue: number
	detectionThreshold: number
}

function CircularProgressWithLabel(props: { value: number }) {
	let value = props.value / 10
	let colorvalue: 'error' | 'warning' | 'success' =
		value >= 7 ? 'error' : value > 4 ? 'warning' : 'success'
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress variant='determinate' {...props} color={colorvalue} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography
					variant='caption'
					component='div'
					color='#C9D3F2'
				>{`${value.toFixed(2)}`}</Typography>
			</Box>
		</Box>
	)
}

const SmellListTable = (props: {
	data: ArchitecturalSmell[]
	setSelectedSmellId: Dispatch<SetStateAction<number | undefined>>
}) => {
	const tableState = useReactiveVar(tableStateVar) as TableState;
	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'id',
			label: 'ID',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: number) => {
					return (
						<Button
							color='primary'
							variant='contained'
							onClick={() => {
								props.setSelectedSmellId(value)
							}}
						>
							{value}
						</Button>
					)
				},
			},
		},
		{
			name: 'fullTypeName',
			label: 'Smell Type',
			options: {
				filter: true,
				filterType: 'multiselect',
				filterList: tableState.filterList[1] || [],
				sort: true,
				customBodyRender: (value: string) => {
					if (value === 'Cyclic Dependency')
						return (
							<>
								<img
									className='smellIcon'
									src={Cyclic}
									alt='Cyclic Dependency icon'
								/>{' '}
								Cyclic Dependency
							</>
						)
					else if (value === 'Hublike Dependency')
						return (
							<>
								<img
									className='smellIcon'
									src={HubLike}
									alt='HubLike Dependency icon'
								/>{' '}
								Hublike Dependency
							</>
						)
					else if (value === 'God Component')
						return (
							<>
								<img
									className='smellIcon'
									src={God}
									alt='God Dependency icon'
								/>{' '}
								God Component
							</>
						)
					else if (value === 'Unstable Dependency')
						return (
							<>
								<img
									className='smellIcon'
									src={Unstable}
									alt='Unstable Dependency icon'
								/>{' '}
								Unstable Dependency
							</>
						)
					else return value
				},
			},
		},
		{
			name: 'tdi',
			label: 'TechDebt Index',
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: 'severity',
			label: 'Severity',
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value: number) => {
					return <CircularProgressWithLabel value={value * 10} />
				},
			},
		},
		{
			name: 'level',
			label: 'Affected Type',
			options: {
				filter: true,
				sort: true,
				filterList: tableState.filterList[4] || [],
				filterType: 'multiselect',
			},
		},
		{
			name: 'affectedComponents',
			label: 'Affected Elements',
			options: {
				filter: true,
				filterType: 'multiselect',
				sort: true,
				filterList: tableState.filterList[5] || [],
				customBodyRender: (value: string) => {
					return (
						<Tooltip title={value} placement="top" arrow>
							<Typography noWrap>{value.substring(0, 26) + (value.length > 26 ? '...' : '')}</Typography>
						</Tooltip>
					);
				},
			},
		},
		{
			name: 'size',
			label: 'Size',
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: 'affectedComponents',
			label: 'Affected Elements (complete)',
			options: {
				display: 'excluded',
				filterList: tableState.filterList[7] || [],
			},
		},
		{
			name: 'fanIn',
			label: 'Incoming dependencies (Fan In)',
			options: {
				display: 'excluded',
				filterList: tableState.filterList[8] || [],
			},
		},
		{
			name: 'fanOut',
			label: 'Outgoing Dependencies (Fan Out)',
			options: {
				display: 'excluded',
				filterList: tableState.filterList[9] || [],
			},
		},
		{
			name: 'affectedCentre',
			label: 'Adaptive threshold of total fan in and fan out',
			options: {
				display: 'excluded',
				filter: false,
			},
		},
		{
			name: 'detectionRule',
			label: 'Detection rule',
			options: {
				display: 'excluded',
				filterList: tableState.filterList[11] || [],
			},
		},
		{
			name: 'detectionValue',
			label: 'Detection Value',
			options: {
				display: 'excluded',
				filterList: tableState.filterList[12] || [],
			},
		},
		{
			name: 'detectionThreshold',
			label: 'Detection Threshold',
			options: {
				display: 'excluded',
				filterList: tableState.filterList[13] || [],
			},
		},
	]

	const options: MUIDataTableOptions = {
		page: tableState.page,
		rowsPerPage: tableState.rowsPerPage,
		selectableRows: 'none',
		expandableRowsHeader: false,
		expandableRows: true,
		onFilterChange: (
			changedColumn: string | MUIDataTableColumn | null,
			filterList: MUIDataTableState['filterList'],
			type: FilterType | 'chip' | 'reset',
			changedColumnIndex: number,
			displayData: DisplayData,
		) => {
			tableStateVar({ ...tableStateVar(), filterList });
		},
		renderExpandableRow: (rowData, rowMeta) => {
			return (
				<>
					<tr>
						<td
							colSpan={8}
							className={
								rowMeta.rowIndex % 2 === 0 ? 'readmore_odd' : 'readmore_even'
							}
						>
							{rowData[7] ? (
								<div className='moreInfo'>
									{rowData[7]}
									<h5>Affected Elements (complete)</h5>
								</div>
							) : null}
							{rowData[8] ? (
								<div className='moreInfo'>
									{rowData[8]}
									<h5>Incoming dependencies (Fan In)</h5>
								</div>
							) : null}
							{rowData[9] ? (
								<div className='moreInfo'>
									{rowData[9]}
									<h5>Outgoing Dependencies (Fan Out)</h5>
								</div>
							) : null}
							{rowData[10] ? (
								<div className='moreInfo'>
									{parseFloat(rowData[13]).toFixed(2)}
									<h5>Threshold</h5>
								</div>
							) : null}
							{rowData[11] ? (
								<div className='moreInfo'>
									{rowData[11]}
									<h5>Detection rule</h5>
								</div>
							) : null}
							{rowData[12] ? (
								<div style={{ height: '400px' }}>
									<Chart
										options={getSmellsGauge(
											parseFloat(rowData[12]),
											parseFloat(rowData[13])
										)}
									/>
								</div>
							) : null}
						</td>
					</tr>
					<tr className='separator'></tr>
				</>
			)
		},
	}
	let smellList: ISmell[] = []

	props.data?.map((smell) => {
		if (smell.type !== 'layeredCycle') {
			let obj: ISmell = {
				id: smell.id,
				fullTypeName: smell.fullTypeName,
				level: smell.affectedConstructType.prettyName ?? '',
				size: smell.size ?? 0,
				affectedComponents: '',
				tdi: 0,
				severity: 0,
				fanIn: smell.cardInfo.fanIn ?? 0,
				fanOut: smell.cardInfo.fanOut ?? 0,
				affectedCentre: smell.cardInfo.affectedCentre ?? '',
				detectionRule: smell.cardInfo.detectionRule ?? '',
				detectionValue: smell.cardInfo.detectionValue ?? 0,
				detectionThreshold: smell.cardInfo.detectionThreshold ?? 0,
			}

			smell.properties?.map((prop) => {
				if (prop?.key === 'ATDI') obj.tdi = parseInt(prop?.value ?? '0')
				else if (prop?.key === 'Severity')
					obj.severity = parseInt(prop?.value ?? '0')
			})

			obj.affectedComponents =
				smell.affectedComponents?.map((comp) => comp?.name ?? '').join(', ') ??
				''
			smellList.push(obj)
		}
	})

	for (let i = 0; i < props.data?.length; i++) {
		let el = props.data[i]
		if (el.type !== 'layeredCycle') {
		}
	}

	return (
		<div className='myDataTable'>
			<MUIDataTable
				title={'Architectural smells'}
				data={smellList}
				columns={columns}
				options={options}
			/>
		</div>
	)
}

export default SmellListTable
