import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'Components/Context/SnackbarContext'
import WithLoading from 'Components/HOC/WithLoading'
import {
  AnalysisOptionsInput,
  PathFilterInput,
  ProgrammingLanguage,
} from 'Model/arcan-types'
import { ITreeProjectNode } from 'Model/utils'
import {
  ISelectAlgorithm,
  ISelectChip,
  ITreeInitValues,
  AnalysisTags,
  AnalysisTree,
  IAnalysisTree,
  IFormValues,
} from 'Templates/Analysis'
import {
  useAnalyseProjectJobMutation,
  useLicenceQuery,
  useUpdateProjectMutation,
} from 'Queries/AnalyseProject'
import {
  useGetAlgorithmNames,
  useGetAnalysisConfigLazy,
  useGetLazyTree,
} from 'Queries/Projects'
import { ISelectFrameworks } from 'Templates/Analysis/AnalysisTags'

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const algorithmsNames: {
  [key: string]: string
} = {
  SMELL_DETECTOR: 'Smell Detector',
  COMPONENT_METRIC: 'Component Metrics',
  SMELL_CHARACTERISTIC: 'Smell characteristics',
}

const algorithmsOrder: {
  [key: string]: number
} = {
  SMELL_DETECTOR: 1,
  COMPONENT_METRIC: 2,
  SMELL_CHARACTERISTIC: 3,
}

const TagsProjectWithLoading = WithLoading(AnalysisTags)
const TreeProjectWithLoading = React.forwardRef(
  (props: IAnalysisTree & { loading: boolean }, ref) => {
    const TreeComponet = WithLoading(AnalysisTree)

    return <TreeComponet {...props} formRef={ref} />
  }
)

interface IAnlysisData {
  exist?: boolean
}

const AnalysisData = ({ exist = false }: IAnlysisData) => {
  const [SelectedAlgorithms, setSelectedAlgorithms] = useState<
    ISelectAlgorithm[]
  >([])
  const [TreeProps, setTreeProps] = useState<ITreeInitValues>()
  const [FormInitValues, setFormInitValues] = useState<IFormValues>({
    type: 'latest',
    matcher: [],
    pathFilters: [
      {
        includeGlobPatterns: '',
        excludeGlobPatterns: '',
      },
    ],
  })

  const navigate = useNavigate()
  const { projectId } = useParams<{ projectId: string }>()

  const [
    getProjectConfiguration,
    { loading: loadingConfig, data: dataConfig },
  ] = useGetAnalysisConfigLazy()
  const { loading: loadingTags, algorithms } = useGetAlgorithmNames()
  const [getDirTree, { data: dataTree, loading: loadingTree }] =
    useGetLazyTree()
  const [analyseProject, { loading: submitting }] =
    useAnalyseProjectJobMutation()
  const [updateProject, { loading: submittingUpdate }] =
    useUpdateProjectMutation()
  const { handleOpen } = useSnackbar()
  const formRef = useRef<any>()

  useEffect(() => {
    if (!projectId) return

    getProjectConfiguration({ variables: { projectId: parseInt(projectId) } })
  }, [projectId])

  const licence = useLicenceQuery()
  const limitationDays = Number(
    licence?.data?.licence.limitations.find(i => i.name == "maxGranularityPerProject")?.limit ?? `${Number.MAX_VALUE}`
  );

  useEffect(() => {
    if (!projectId) return

    getDirTree({
      variables: {
        projectId: parseInt(projectId),
      },
    })
  }, [projectId, getDirTree])

  useEffect(() => {
    if (algorithms) {
      if (exist && loadingConfig) return

      let selectedAlgorithms: string[] = []
      let matcher: {
        pattern: string
        paths: string
      }[] = []
      let filters: {
        includeGlobPatterns: string
        excludeGlobPatterns: string
      }[] = []
      if (exist && dataConfig) {
        const { programmingLanguage, analysisConfiguration: analysisConfig } =
          dataConfig.projectById
        selectedAlgorithms = selectedAlgorithms.concat(
          analysisConfig.metrics.componentMetrics.map(comp => comp.name)
        )
        selectedAlgorithms = selectedAlgorithms.concat(
          analysisConfig.metrics.smellCharacteristics.map(smell => smell.name)
        )
        selectedAlgorithms = selectedAlgorithms.concat(
          analysisConfig.detectors.smellDetectors.map(smell => smell.name)
        )

        const { includeMatchers, sourcePathMatchers } = analysisConfig

        if (
          programmingLanguage === ProgrammingLanguage.C ||
          programmingLanguage === ProgrammingLanguage.Cpp
        ) {
          matcher = includeMatchers?.length
            ? includeMatchers.map(matcher => ({
              pattern: matcher.globPattern,
              paths: matcher.paths.join(';'),
            }))
            : [{ pattern: '', paths: '' }]
        }
        filters = sourcePathMatchers.map(path => ({
          includeGlobPatterns: path.includeGlobPatterns.join(';'),
          excludeGlobPatterns: path.excludeGlobPatterns.join(';'),
        }))
      }

      const newSelectedAlgorithms: ISelectAlgorithm[] = []
      algorithms.forEach(algorithm => {
        const chips: ISelectChip[] = algorithm.names.map(name => ({
          value: name?.key ?? '',
          text: name?.value ?? '',
          selected: exist ? selectedAlgorithms.includes(name?.key ?? '') : true,
        }))

        newSelectedAlgorithms.push({
          value: algorithm.typeName,
          title: algorithmsNames[algorithm.typeName],
          chips: chips,
          order: algorithmsOrder[algorithm.typeName],
        })
      })

      setSelectedAlgorithms(newSelectedAlgorithms)

      setFormInitValues({
        type: 'latest',
        matcher,
        pathFilters: filters ?? [],
      })
    }
  }, [algorithms, exist, loadingConfig])

  const handleAnalyseProject = (selectedAlgorithms: ISelectAlgorithm[], selectedFrameworks: ISelectFrameworks) => {
    if (projectId) {
      const { checked } = TreeProps ?? {}
      let dirTreeJSON = null
      if (dataTree?.projectById?.dirTreeRoot?.dirTreeJson && FormInitValues.type === 'latest') {
        const tree: ITreeProjectNode = JSON.parse(dataTree.projectById.dirTreeRoot.dirTreeJson)

        tree.children?.forEach(child => updateNodes(child, checked ?? []))
        dirTreeJSON = JSON.stringify(tree)
      }

      handleOpen(
        exist ? 'Started updating project!' : 'Started analyzing project!'
      )

      const includeDirectives = FormInitValues.matcher.length
        ? FormInitValues.matcher.map((m: any) => ({
          globPattern: m.pattern,
          paths: m.paths.split(';'),
        }))
        : null

      const sourcePathFilters: PathFilterInput[] =
        FormInitValues.type === 'evolution'
          ? FormInitValues.pathFilters?.map(filter => ({
            includeGlobPatterns: filter.includeGlobPatterns.split(';'),
            excludeGlobPatterns: filter.excludeGlobPatterns.split(';'),
          }))
          : []
      const analysisInterval =
        FormInitValues.type === 'evolution'
          ? {
            startDate: moment(FormInitValues.startDate).format('yyyy-MM-DD'),
            endDate: moment(FormInitValues.endDate).format('yyyy-MM-DD'),
            intervalInDays: FormInitValues.minDays ?? 0,
          }
          : null

      const input: {
        variables: {
          analysisOptions: AnalysisOptionsInput
        }
      } = {
        variables: {
          analysisOptions: {
            projectId: parseInt(projectId),
            detectors: {
              detectorNames:
                selectedAlgorithms
                  .find(algo => algo.title === algorithmsNames.SMELL_DETECTOR)
                  ?.chips.filter(chip => chip.selected)
                  .map(chip => chip.value) ?? [],
            },
            metrics: {
              componentMetricNames:
                selectedAlgorithms
                  .find(algo => algo.title === algorithmsNames.COMPONENT_METRIC)
                  ?.chips.filter(chip => chip.selected)
                  .map(chip => chip.value) ?? [],
              smellCharacteristicNames:
                selectedAlgorithms
                  .find(
                    algo => algo.title === algorithmsNames.SMELL_CHARACTERISTIC
                  )
                  ?.chips.filter(chip => chip.selected)
                  .map(chip => chip.value) ?? [],
            },
            dirTreeJSON,
            includeDirectives,
            sourcePathFilters,
            analysisInterval,
            parseDynamicBeanReferences: selectedFrameworks.isSpringProject
          },
        },
      }

      if (exist) {
        updateProject(input)
          .then(({ data }) => {
            if (data?.updateProject) {
              handleOpen('Analysis configuration updated!', 'success')
              navigate('/')
            } else {
              handleOpen('Error on updating the configuration', 'error')
            }
          })
          .catch(err => handleOpen(`${err}`, 'error', 10000))
      } else {
        analyseProject(input)
          .then(() => {
            navigate('/')
          })
          .catch(err => handleOpen(`${err}`, 'error', 10000))
      }
    }
  }

  const updateNodes = (node: ITreeProjectNode, checked: string[]) => {
    node.included = node.path ? checked.includes(node.path) : false
    node.children?.forEach(child => updateNodes(child, checked))
  }

  const basePath = exist
    ? `/project/edit/${projectId}`
    : `/project/new/${projectId}`
  const projectLanguageStr = capitalizeFirstLetter(dataTree?.projectById?.programmingLanguage ?? 'Java') as keyof typeof ProgrammingLanguage
  const projectLanguage = ProgrammingLanguage[projectLanguageStr]
  return (
    <Routes>
      <Route
        path='tree'
        element={
          <TreeProjectWithLoading
            ref={formRef}
            loading={loadingTree}
            jsonValue={dataTree?.projectById?.dirTreeRoot?.dirTreeJson ?? undefined}
            initValues={TreeProps}
            finishAction={(checked, indeterminated, expanded, formValues) => {
              setTreeProps({
                checked,
                expanded,
                indeterminated,
              })
              setFormInitValues(formValues)

              if (formValues.type === 'evolution') {
                if (!!!formValues.startDate || !!!formValues.endDate) {
                  handleOpen(
                    'Please enter a valid start and end date',
                    'warning',
                    5000
                  )
                  return
                }
                const startDate = moment(formValues.startDate)
                const endDate = moment(formValues.endDate)
                if (!startDate.isValid() || !endDate.isValid()) {
                  handleOpen(
                    'Please select valid start date and end date.',
                    'warning',
                    5000
                  )
                }
                const diffDays = endDate.diff(startDate, 'days')
                const minDays = Math.max(formValues.minDays ?? 1, 1)
                const intervals = (diffDays / minDays);
                if (formValues.minDays && intervals > limitationDays) {
                  handleOpen(
                    `Your licence does not allow to analyse the given time interval (${diffDays} days) with minimum days between commits (${minDays}) for a total of ${intervals} possible versions analysed.`,
                    "warning",
                    10000
                  )
                  return
                }
              }
              navigate(`${basePath}/tags`)
            }
            }
            programmingLanguage={
              dataConfig?.projectById.programmingLanguage ?? ''
            }
            formInitValues={FormInitValues}
            projectName={dataTree?.projectById?.name ?? ''}
          />
        }
      />
      <Route
        path='tags'
        element={
          <TagsProjectWithLoading
            projectName={dataTree?.projectById?.name ?? ''}
            projectLanguage={projectLanguage}
            cancelAction={() => navigate('/')}
            loading={loadingTags}
            initSelectedAlgorithms={SelectedAlgorithms}
            continueAction={(selectedAlgorithms, selectedFrameworks) => {
              setSelectedAlgorithms(selectedAlgorithms)
              handleAnalyseProject(selectedAlgorithms, selectedFrameworks)
            }}
            backAction={selectedAlgorithms => {
              setSelectedAlgorithms(selectedAlgorithms)
              navigate(`${basePath}/tree`)
            }}
            completeBtnText={exist ? 'UPDATE' : 'ANALYZE'}
            submitting={submitting || submittingUpdate}
          />
        }
      />
    </Routes>
  )
}

export default AnalysisData
