import { Box, Container, Grid, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import { SimpleButtonGroup } from 'Components/ButtonGroup'
import { ITreeNode, Tree } from 'Components/Tree'
import BaseGraph from 'Components/Cytoscape/BaseGraph'
import {
  Component,
  Container as ContainerType, DependencyEdge,
  DependencyGraph, DependencyLocation, InclusionEdge,
  Maybe,
  MembershipEdge,
  Unit,
} from '../../Model/arcan-types'
import cytoscape, {
  EdgeDefinition,
  NodeCollection,
  NodeDefinition,
  SingularElementArgument,
} from 'cytoscape'
import DependencyGraphEditor from './DependencyGraphEditor'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import MUIDataTable from "mui-datatables";

const StyledInfoValue = styled('div')(({ theme }) => ({
  fontSize: '18px',
  overflowWrap: 'break-word',
  marginBottom: theme.spacing(2), // adds space below the value
}))
const StyledInfoKey = styled('div')(({ theme }) => ({
  color: theme.customPalette.title,
  marginBottom: '.5rem',
  overflowWrap: 'break-word',
  fontWeight: 300,
}))

const CodeContainer = styled('code')(() => ({
  counterReset: 'line',
}))

const CodeRow = styled('div')(({ theme }) => ({
  color: theme.customPalette.text,
  '&:before': {
    color: theme.customPalette.text,
    counterIncrement: 'line',
    content: 'counter(line)',
    display: 'inline-block',
    borderRight: '1px solid #ddd',
    padding: '0 .5em',
    marginRight: '.5em',
    minWidth: '25px',
  },
}))

const HighlightCodeRow = styled('div')(({ theme }) => ({
  color: theme.customPalette.text,
  background: theme.customPalette.codeHighlight,
  '&:before': {
    color: theme.customPalette.text,
    counterIncrement: 'line',
    content: 'counter(line)',
    display: 'inline-block',
    borderRight: '1px solid #ddd',
    padding: '0 .5em',
    marginRight: '.5em',
    minWidth: '25px',
  },
}))

interface ISource {
  file: string
  dependant: string
  dependantUpon: string
  weight: number
  lines: string[]
  locationList: DependencyLocation[]
}

const findNode = (nodes: ITreeNode[], edge: Component): ITreeNode | null => {
  let result: ITreeNode | null = null

  for (let node of nodes) {
    if (node.nodeId === `${edge.id}`) {
      result = node
      break
    }

    result = findNode(node.children ?? [], edge)
    if (result != null) break
  }

  return result
}

const getTreeNodes = (dependencyGraph: DependencyGraph) => {
  let treeNodes: Map<String, ITreeNode> = new Map()
  let membershipEdges = (dependencyGraph.membershipEdges as MembershipEdge[]) ?? []
  // let inclusionEdges = (dependencyGraph.inclusionEdges as InclusionEdge[]) ?? []
  let units = (dependencyGraph.allUnits as Unit[])??[]
  let containers = (dependencyGraph.allContainers as ContainerType[])??[]
  let componentsMap = new Map<number, Component>()
  units.forEach(u => componentsMap.set(u.id, u))
  containers.forEach(c => componentsMap.set(c.id, c))

  membershipEdges?.forEach(edge => {
    let memberComponent = componentsMap.get(edge.member?.id??-1)
    let parentComponent = componentsMap.get(edge.parent?.id??-1)

    let memberNode = treeNodes.get(`${memberComponent?.id}`)
    if(!memberNode){
      memberNode = {
        label: memberComponent?.simpleName ?? '',
        nodeId: `${memberComponent?.id}`,
        children: [],
      }
      treeNodes.set(memberNode.nodeId, memberNode)
    }

    let parentNode = treeNodes.get(`${parentComponent?.id}`)
    if(!parentNode) {
      parentNode = {
        label: parentComponent?.simpleName ?? '',
        nodeId: `${parentComponent?.id}`,
        children: [],
      }
      treeNodes.set(parentNode.nodeId, parentNode)
    }

    parentNode.children?.push(memberNode)
    memberNode.parent = parentNode
  })
  var rootNodes = Array.from(treeNodes.values()).filter(node => !node.parent)
  return rootNodes
}

const getNeighbourNodes = (
  node: NodeCollection,
  depth: number,
  prevNodes: SingularElementArgument[]
) => {
  let res: SingularElementArgument[] = prevNodes
  const children = node
    .neighborhood()
    .toArray()
    .filter(val => {
      return res.findIndex(el => el.data('id') === val.data('id')) === -1
    })

  res = res.concat(children)
  if (depth > 1) {
    children.forEach(child => {
      const childRes = getNeighbourNodes(
        child as NodeCollection,
        depth - 1,
        res
      ).filter(val => {
        return res.findIndex(el => el.data('id') === val.data('id')) === -1
      })
      res = res.concat(childRes)
    })
  }

  return res
}

export interface IEditorValues {
  [key: string]: IColorOption | string
}

export interface IColorOption {
  selected: boolean
  color: string
}

interface IDependecyGraph {
  dependencyGraph?: DependencyGraph
  edges?: DependencyEdge[],
  handleSelectItem?: (item: NodeCollection, type: 'node' | 'edge') => void
  tableTitle?: string
}

interface IGraphOptions {
  nodes: NodeDefinition[]
  edges: EdgeDefinition[]
  graphLayout: {
    nodeLabel: string
    edgeLabel: string
  }
  actions?: {
    icon: JSX.Element
    action: () => void
    tooltip: string
  }[]
  setCyCoreExt?: (arg0: cytoscape.Core) => void
  defaultLayout?: string,
  tapEdgeHandler?: cytoscape.EventHandler
  tapHandler?: cytoscape.EventHandler
}

const DependencyGraphTemplate = ({ dependencyGraph, edges, handleSelectItem, tableTitle }: IDependecyGraph) => {
  const [SelectedPage, setSelectedPage] =
    useState<'settings' | 'graph'>('settings')
  const [TreeNodes, setTreeNodes] = useState<ITreeNode[]>([])
  const [Checked, setChecked] = useState<string[]>([])
  const [Expanded, setExpanded] = useState<string[]>([])
  const [Indeterminated, setIndeterminated] = useState<string[]>([])
  const [GraphOptions, setGraphOptions] = useState<IGraphOptions>({
    nodes: [],
    edges: [],
    graphLayout: {
      nodeLabel: '',
      edgeLabel: '',
    },
  })
  const [EditorValues, setEditorValues] = useState<IEditorValues>({
    container: {
      selected: true,
      color: '#C3C3C3',
    },
    unit: {
      selected: true,
      color: '#FF9800',
    },
    inheritance: {
      selected: true,
      color: '#4CAF50',
    },
    implementation: {
      selected: true,
      color: '#D32F2F',
    },
    membership: {
      selected: true,
      color: '#C8A2FF',
    },
    inclusion: {
      selected: true,
      color: '#fff151',
    },
    nested: {
      selected: true,
      color: '#4766FF',
    },
    dependency: {
      selected: true,
      color: '#19B2FF',
    },
    containerDependency: {
      selected: true,
      color: '#186B5F',
    },
    labelNodes: 'name',
    labelEdges: 'weight',
  })
  const [NeiGraphOptions, setNeiGraphOptions] =
    useState<IGraphOptions | null>({
      nodes: [],
      edges: [],
      graphLayout: {
        nodeLabel: '',
        edgeLabel: '',
      },
      tapHandler: evt => {
        if (handleSelectItem) {
          handleSelectItem(evt.target as NodeCollection, 'node')
        }
      },
      tapEdgeHandler: evt => {
        if (handleSelectItem) {
          handleSelectItem(evt.target as NodeCollection, 'edge')
        }
      }
    })
  const [SelectedNode, setSelectedNode] = useState<NodeCollection | null>(null)
  const [DepGraphCore, setDepGraphCore] = useState<cytoscape.Core>()
  const [PreviouseNode, setPreviousNode] = useState<NodeCollection[]>([])
  const [Depth, setDepth] = useState(1)

  const neiRef = useRef<HTMLDivElement>(null)

  const [Sources, setSources] = useState<ISource[]>([])

  useEffect(() => {
    if (dependencyGraph) {
      const treeNodes = getTreeNodes(dependencyGraph)

      setTreeNodes(treeNodes)
    }
  }, [dependencyGraph])

  useEffect(() => {
    if (edges) {
      const sources: ISource[] = edges.reduce((acc, edge) => {
        const dependantProps = new Map(
            edge.dependant.properties?.map(prop => {
              return [prop!.key as string, prop!.value as string]
            })
        )

        const existingSource = acc.find(s => s.dependant === edge.dependant.name)
        if (existingSource) {
          existingSource.dependantUpon = `${existingSource.dependantUpon}, ${edge.dependedUpon.name}`
          existingSource.lines =
              edge.codeSnippet?.fileLines?.length ?? 0 > existingSource.lines.length
                  ? edge.codeSnippet.fileLines
                  : existingSource.lines
          existingSource.locationList = existingSource.locationList.concat(
              edge.codeSnippet?.locations ?? []
          )
        } else {
          acc.push({
            file: dependantProps.get('filePathRelative') ?? '',
            dependant: edge.dependant.name,
            dependantUpon: edge.dependedUpon.name,
            weight: edge.weight,
            lines: edge.codeSnippet?.fileLines ?? [],
            locationList: edge.codeSnippet?.locations ?? [],
          })
        }

        return acc
      }, [] as ISource[])

      setSources(sources)
    }
  }, [SelectedNode, edges, NeiGraphOptions])




  useEffect(() => {
    if (SelectedNode) {
      const neiElements = getNeighbourNodes(SelectedNode, Depth, [])
      const nodes: NodeDefinition[] = neiElements
        .filter(el => el.group() === 'nodes')
        .map(node => ({
          group: 'nodes',
          data: node.data(),
        }))
      nodes.push({
        group: 'nodes',
        data: SelectedNode.data(),
      })

      var actions = [
        {
          icon: <AccountTreeIcon />,
          action: () => {
            setSelectedNode(null)
            setNeiGraphOptions(null)
            setPreviousNode([])
            setDepth(1)
          },
          tooltip: 'Back to dependency graph',
        },
      ]

      if (PreviouseNode.length) {
        actions.push({
          icon: <ArrowBackIosIcon />,
          action: () => {
            const prevNodesArray = [...PreviouseNode]
            const lastNode = prevNodesArray.pop()
            if (lastNode) {
              setSelectedNode(lastNode)
              setPreviousNode(prevNodesArray)
            }
          },
          tooltip: 'Back to previous node',
        })
      }


      setNeiGraphOptions({
        nodes,
        edges: neiElements
          .filter(el => el.group() === 'edges')
          .map(node => ({
            group: 'edges',
            data: node.data(),
          })),
        tapHandler: evt => {
          if (handleSelectItem) {
            handleSelectItem(evt.target as NodeCollection, 'node')
          }
        },
        tapEdgeHandler: evt => {
          if (handleSelectItem) {
            handleSelectItem(evt.target as NodeCollection, 'edge')
          }
        },
        graphLayout: {
          nodeLabel: EditorValues.labelNodes as string,
          edgeLabel: EditorValues.labelEdges as string,
        },
        actions,
        defaultLayout: 'klay',
      })
    } else {
      setNeiGraphOptions(null)
    }
  }, [SelectedNode, Depth])

  const buttonGroupProps = !NeiGraphOptions
    ? {
        selected: SelectedPage,
        label: 'Dependency Graph',
        buttons: [
          {
            value: 'settings',
            text: 'Settings',
            icon: <SettingsIcon />,
            action: () => setSelectedPage('settings'),
          },
          {
            value: 'graph',
            text: 'Graph',
            icon: <AccountTreeIcon />,
            action: () => {
              const {
                unit,
                container,
                nested,
                membership,
                dependency,
                containerDependency,
                inheritance,
                implementation,
                  inclusion,
                labelNodes,
                labelEdges,
              } = EditorValues
              if (dependencyGraph) {
                const {
                  allUnits,
                  allContainers,
                  membershipEdges,
                  dependencyEdges,
                  hierarchyEdges,
                    inclusionEdges
                } = dependencyGraph

                const filteredsUnits: Unit[] = (unit as IColorOption).selected
                  ? (allUnits as Unit[])?.filter(u =>
                      Checked.includes(`${u?.id}`)
                    )
                  : []
                const filteredContainers: ContainerType[] = (
                  container as IColorOption
                ).selected
                  ? (allContainers as ContainerType[])?.filter(c =>
                      Checked.includes(`${c?.id}`)
                    )
                  : []

                const filteredSelectedNodeIds = filteredsUnits
                  ?.map(u => u?.id ?? -1)
                  .concat(filteredContainers?.map(c => c?.id ?? -1))

                const filteredMembershipEdges = membershipEdges?.filter(
                  me =>
                    filteredSelectedNodeIds.includes(me?.member?.id ?? -1) &&
                    filteredSelectedNodeIds.includes(me?.parent?.id ?? -1)
                )
                const filteredDependencyEdges = dependencyEdges?.filter(
                  me =>
                    filteredSelectedNodeIds.includes(me?.dependant?.id ?? -1) &&
                    filteredSelectedNodeIds.includes(me?.dependedUpon?.id ?? -1)
                )
                const filteredHierarchyEdges = hierarchyEdges?.filter(
                  me =>
                    filteredSelectedNodeIds.includes(me?.parent?.id ?? -1) &&
                    filteredSelectedNodeIds.includes(me?.children?.id ?? -1)
                )
                const filteredInclusionEdges = inclusionEdges?.filter(
                    me =>
                        filteredSelectedNodeIds.includes(me?.header?.id ?? -1) &&
                        filteredSelectedNodeIds.includes(me?.implementation?.id ?? -1)
                )

                let nodes: NodeDefinition[] = []
                filteredsUnits?.forEach(node => {
                  nodes.push({
                    group: 'nodes',
                    data: {
                      id: `${node?.id}`,
                      label: node?.label,
                      name: node?.name,
                      simpleName: node?.simpleName,
                      constructType: node?.constructType?.prettyName,
                      filePathRelative: node?.relativeFilePath,
                      backgroundColor: (unit as IColorOption).color,
                    },
                  })
                })
                filteredContainers?.forEach(cont => {
                  nodes.push({
                    group: 'nodes',
                    data: {
                      id: `${cont?.id}`,
                      label: cont?.label,
                      name: cont?.name,
                      simpleName: cont?.simpleName,
                      constructType: cont?.constructType?.prettyName,
                      filePathRelative: cont?.relativeFilePath,
                      backgroundColor: (container as IColorOption).color,
                    },
                  })
                })

                let edges: EdgeDefinition[] = []
                filteredMembershipEdges?.forEach(edge => {
                  if (edge?.label === 'belongsTo') {
                    const useMembership = (membership as IColorOption).selected;
                    if (useMembership) {
                      edges.push({
                        group: 'edges',
                        data: {
                          id: `${edge?.id}`,
                          source: `${edge?.member?.id}`,
                          target: `${edge?.parent?.id}`,
                          label: edge?.label,
                          lineColor: (membership as IColorOption).color,
                          weight: '',
                        },
                      });
                    }
                  } else {
                    const useNested = (nested as IColorOption).selected;
                    if (useNested) {
                      edges.push({
                        group: 'edges',
                        data: {
                          id: `${edge?.id}`,
                          source: `${edge?.member?.id}`,
                          target: `${edge?.parent?.id}`,
                          label: edge?.label,
                          lineColor: (nested as IColorOption).color,
                          weight: '',
                        },
                      });
                    }
                  }
                });

                filteredDependencyEdges?.forEach(edge => {
                  const color =
                    edge?.label === 'containerIsAfferentOf'
                      ? (containerDependency as IColorOption).color
                      : (dependency as IColorOption).color

                  if (
                    (edge?.label === 'containerIsAfferentOf' &&
                      (containerDependency as IColorOption).selected) ||
                    (edge?.label !== 'containerIsAfferentOf' &&
                      (dependency as IColorOption).selected)
                  )
                    edges.push({
                      group: 'edges',
                      data: {
                        id: `${edge?.id}`,
                        source: `${edge?.dependant?.id}`,
                        target: `${edge?.dependedUpon?.id}`,
                        label: edge?.label,
                        weight: edge?.weight,
                        lineColor: color,
                      },
                    })
                })
                filteredHierarchyEdges?.forEach(edge => {
                  const color =
                    edge?.label === 'isChildOf'
                      ? (inheritance as IColorOption).color
                      : (implementation as IColorOption).color

                  if (
                    (edge?.label === 'isChildOf' &&
                      (inheritance as IColorOption).selected) ||
                    (edge?.label === 'isImplementationOf' &&
                      (implementation as IColorOption).selected)
                  )
                    edges.push({
                      group: 'edges',
                      data: {
                        id: `${edge?.id}`,
                        source: `${edge?.children?.id}`,
                        target: `${edge?.parent?.id}`,
                        label: edge?.label,
                        lineColor: color,
                        weight: '',
                      },
                    })
                })

                filteredInclusionEdges?.forEach(edge => {
                  const color = (inclusion as IColorOption).color

                  if (
                      (edge?.label === 'includes' &&
                          (inclusion as IColorOption).selected)
                  )
                    edges.push({
                      group: 'edges',
                      data: {
                        id: `${edge?.id}`,
                        source: `${edge?.implementation?.id}`,
                        target: `${edge?.header?.id}`,
                        label: edge?.label,
                        lineColor: color,
                        weight: '',
                      },
                    })
                })

                setGraphOptions({
                  nodes: nodes,
                  edges: edges,
                  graphLayout: {
                    nodeLabel: labelNodes as string,
                    edgeLabel: labelEdges as string,
                  },
                })
              }
              setSelectedPage('graph')
            },
          },
        ],
      }
    : {
        selected: '',
        label: 'Neighborhood Graph',
      }

  const treeProps = {
    nodes: TreeNodes,
    checked: Checked,
    expanded: Expanded,
    indeterminated: Indeterminated,
    setChecked,
    setExpanded,
    setIndeterminated,
    showOptionsPanel: true,
    nodeToOpen: 3,
  }

  let selectedNodeInfo: {
    [key: string]: any
  } = {}
  if (SelectedNode) {
    const nodeId = SelectedNode.data('id')
    let unit: Maybe<Unit> | ContainerType | undefined =
      dependencyGraph?.allUnits?.find(u => u?.id === parseInt(nodeId))

    if (!unit) {
      unit = dependencyGraph?.allContainers?.find(
        u => u?.id === parseInt(nodeId)
      )
    }

    if (unit) {
      selectedNodeInfo = {
        id: nodeId,
      }

      unit.properties?.forEach(prop => {
        if (!prop || !prop?.key) return
        selectedNodeInfo[prop?.key] = prop?.value ?? ''
      })
    }
  }

  return (
    <Container
      maxWidth={SelectedPage === 'settings' ? 'xl' : false}
      sx={{ mt: 2, height: 'calc(100% - 115px)' }}
    >
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12}>
          <SimpleButtonGroup {...buttonGroupProps} />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          xl={6}
          sx={{
            height: 'calc(100% - 65px)',
            display: SelectedPage === 'settings' ? '' : 'none',
          }}
        >
          <Tree {...treeProps} />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          xl={6}
          sx={{
            height: 'calc(100% - 65px)',
            display: SelectedPage === 'settings' ? '' : 'none',
          }}
        >
          <DependencyGraphEditor
            editorValues={EditorValues}
            setEditorValues={setEditorValues}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: 'calc(100% - 65px)',
            display: SelectedPage === 'graph' && !NeiGraphOptions ? '' : 'none',
          }}
        >
          <BaseGraphMemo
            {...GraphOptions}
            setCyCoreExt={cyCore => setDepGraphCore(cyCore)}
            tapHandler={evt => {
              const selection = evt.target as NodeCollection | null
              setSelectedNode(selection)
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          ref={neiRef}
          sx={{
            height: 'calc(100% - 65px)',
            display:
              SelectedPage === 'graph' && !!NeiGraphOptions ? 'flex' : 'none',
            flexDirection: 'row',
            gap: '16px',
          }}
        >
          {NeiGraphOptions && (
            <Box sx={{ height: '100%', flex: 2 }}>
              <BaseGraphMemo
                {...NeiGraphOptions}
                tapHandler={evt => {
                  if (handleSelectItem) {
                    handleSelectItem(evt.target as NodeCollection, 'node')
                  }
                  const selection = evt.target as NodeCollection | null
                  if (!selection || !DepGraphCore) return

                  const nodeId = selection.data('id')
                  setSelectedNode(prevSelectedNode => {
                    setPreviousNode(prev => {
                      return prevSelectedNode
                          ? [...prev, prevSelectedNode]
                          : prev
                    })
                    return DepGraphCore.$(`#${nodeId}`)
                  })
                }}
                nodeIdToCenter={SelectedNode?.data('id')}
                enableDepth={true}
                onChangeDepth={val => setDepth(parseInt(val))}
              />
              {NeiGraphOptions && <Box marginTop={10} width={"100%"} className='myDataTable'>
                <MUIDataTable
                    title={tableTitle}
                    data={Sources}
                    columns={[
                      {
                        name: 'file',
                        label: 'File',
                      },
                      {
                        name: 'dependant',
                        label: 'Dependant (Out)',
                      },
                      {
                        name: 'dependantUpon',
                        label: 'Depends upon (In)',
                      },
                      {
                        name: 'weight',
                        label: 'Weight',
                      },
                    ]}
                    options={{
                      selectableRows: 'none',
                      expandableRowsHeader: false,
                      expandableRows: true,
                      renderExpandableRow: (rowData, rowMeta) => {
                        const source = Sources.find(s => s.file === rowData[0])
                        const lines = source?.lines ?? []
                        const highlightedLines = source?.locationList ?? []

                        return (
                            <>
                              <tr className='separator'></tr>
                              <tr>
                                <td colSpan={5}>
                      <pre>
                        <CodeContainer>
                          {lines.map((l, i) => {
                            return highlightedLines.some(
                                l => l.line - 1 <= i && l.endLine - 1 >= i
                            ) ? (
                                <HighlightCodeRow>{l}</HighlightCodeRow>
                            ) : (
                                <CodeRow>{l}</CodeRow>
                            )
                          })}
                        </CodeContainer>
                      </pre>
                                </td>
                              </tr>
                            </>
                        )
                      },
                      textLabels: {
                        body: {
                          noMatch:
                              'Click either an edge or a node to view where dependencies are created.',
                        },
                      },
                    }}
                />
              </Box>}
            </Box>
          )}
          <Box sx={{ height: '100%', flex: 1 }}>
            <h2>Node properties</h2>
            <Grid container spacing={2} padding={2}>
              {Object.keys(selectedNodeInfo).map((k, i) => (
                  <Grid key={i} item xs={6}>
                    <StyledInfoKey>{k}</StyledInfoKey>
                    <StyledInfoValue>{selectedNodeInfo[k]}</StyledInfoValue>
                  </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

const BaseGraphMemo = React.memo(BaseGraph, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next)
})

export default DependencyGraphTemplate
