import {makeVar} from "@apollo/client";

export interface TableState {
    page: number;
    rowsPerPage: number;
    filterList: string[][];
    sortOrder: { name: string; direction: 'asc' | 'desc' } | {};
}

export const tableStateVar = makeVar<TableState>({
    page: 0,
    rowsPerPage: 10,
    filterList: [],
    sortOrder: {},
});

