import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import WithLoading from 'Components/HOC/WithLoading'
import { TemporalStatistics } from 'Model/arcan-types'
import Overview from 'Templates/Project/Overview'
import { useGetOverviewLazy } from 'Queries/Projects'

const OverviewWithLoading = WithLoading(Overview)

const OverviewData = () => {
	const { projectId, versionId } =
		useParams<{ projectId: string; versionId: string }>()
	const [getOverview, { loading, data, error }] = useGetOverviewLazy()

	useEffect(() => {
		if (projectId && versionId) {
			getOverview({
				variables: { projectId: parseInt(projectId), versionId },
			})
		}
	}, [getOverview, projectId, versionId])

	useEffect(() => {
		if (error) {
			console.log(error)
		}
	}, [error])

	return (
		<OverviewWithLoading
			loading={loading}
			showHeader={false}
			projectOverview={
				data?.projectById?.temporalStatistics ?? ({} as TemporalStatistics)
			}
			versionId={versionId??"unknown"}
		/>
	)
}

export default OverviewData
