export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type AffectsEdge = ArcanEdge & {
  __typename?: 'AffectsEdge';
  affectedComponent?: Maybe<Component>;
  archSmell?: Maybe<ArchitecturalSmell>;
  id: Scalars['Int'];
  label: Scalars['String'];
  property?: Maybe<Scalars['String']>;
};


export type AffectsEdgePropertyArgs = {
  key: Scalars['String'];
};

export type AlgorithmDescriptor = {
  __typename?: 'AlgorithmDescriptor';
  fullName: Scalars['String'];
  name: Scalars['String'];
};

export type AlgorithmNames = {
  __typename?: 'AlgorithmNames';
  names: Array<KeyValuePair>;
  typeName: Scalars['String'];
};

export type AnalysisConfiguration = {
  __typename?: 'AnalysisConfiguration';
  analysisInterval: DateInterval;
  detectors: SmellDetectorsConfiguration;
  includeMatchers: Array<IncludeMatcher>;
  metrics: MetricsConfiguration;
  parseDynamicBeanReferences: Scalars['Boolean'];
  sourcePathMatchers: Array<PathFilter>;
};

export type AnalysisExitStatus = {
  __typename?: 'AnalysisExitStatus';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type AnalysisOptionsInput = {
  analysisInterval?: InputMaybe<DateIntervalInput>;
  detectors: DetectorsInput;
  dirTreeJSON?: InputMaybe<Scalars['String']>;
  includeDirectives?: InputMaybe<Array<IncludeMatcherInput>>;
  metrics: MetricsInput;
  parseDynamicBeanReferences?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
  sourcePathFilters?: InputMaybe<Array<PathFilterInput>>;
};

export type AnalysisSummary = {
  __typename?: 'AnalysisSummary';
  analysedVersions: Scalars['Int'];
  elapsedFormattedString: Scalars['String'];
  elapsedNano: Scalars['String'];
  errorMessages: Array<Scalars['String']>;
  exitStatus: AnalysisExitStatus;
};

export type ArcanEdge = {
  id: Scalars['Int'];
  label: Scalars['String'];
  property?: Maybe<Scalars['String']>;
};


export type ArcanEdgePropertyArgs = {
  key: Scalars['String'];
};

export type ArcanVertex = {
  id: Scalars['Int'];
  label: Scalars['String'];
  property?: Maybe<Scalars['String']>;
};


export type ArcanVertexPropertyArgs = {
  key: Scalars['String'];
};

export type ArchSmellCardInfo = {
  __typename?: 'ArchSmellCardInfo';
  affectedCentre?: Maybe<Scalars['String']>;
  detectionRule?: Maybe<Scalars['String']>;
  detectionThreshold?: Maybe<Scalars['Float']>;
  detectionValue?: Maybe<Scalars['Float']>;
  fanIn?: Maybe<Scalars['Int']>;
  fanOut?: Maybe<Scalars['Int']>;
  numberOfBadDependencies?: Maybe<Scalars['Int']>;
  numberOfContainedChildren?: Maybe<Scalars['Int']>;
  shape?: Maybe<Scalars['String']>;
};

export type ArchitecturalSmell = {
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<ArcanEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  type: Scalars['String'];
};


export type ArchitecturalSmellPropertyArgs = {
  key: Scalars['String'];
};

export type CdSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'CDSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  edgesCreatingTheCycle?: Maybe<Array<Maybe<DependencyEdge>>>;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<DependencyEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  type: Scalars['String'];
};


export type CdSmellPropertyArgs = {
  key: Scalars['String'];
};

export type ChSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'CHSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<ArcanEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  type: Scalars['String'];
};


export type ChSmellPropertyArgs = {
  key: Scalars['String'];
};

export type CodeSnippet = {
  __typename?: 'CodeSnippet';
  fileLines: Array<Scalars['String']>;
  locations: Array<DependencyLocation>;
};

export type Component = {
  constructType: ConstructType;
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parent?: Maybe<Component>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  realFilePath?: Maybe<Scalars['String']>;
  relativeFilePath?: Maybe<Scalars['String']>;
  simpleName?: Maybe<Scalars['String']>;
};


export type ComponentPropertyArgs = {
  key: Scalars['String'];
};

export type ComponentEntityNode = {
  __typename?: 'ComponentEntityNode';
  affectingSmells: Array<Maybe<SmellEntityNode>>;
  affectsEdges: Array<TemporalAffectsEdge>;
  age: Scalars['Int'];
  ageInVersion: Scalars['Int'];
  allInstants: Array<Maybe<InstantNode>>;
  id: Scalars['Int'];
  label: Scalars['String'];
  lastInstant?: Maybe<InstantNode>;
};


export type ComponentEntityNodeAgeInVersionArgs = {
  versionId: Scalars['String'];
  versionIndex: Scalars['Int'];
};

export type ConstructType = {
  __typename?: 'ConstructType';
  name: Scalars['String'];
  prettyName?: Maybe<Scalars['String']>;
};

export type Container = Component & {
  __typename?: 'Container';
  connectedSmells?: Maybe<Array<Maybe<ArchitecturalSmell>>>;
  constructType: ConstructType;
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parent?: Maybe<Container>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  realFilePath?: Maybe<Scalars['String']>;
  relativeFilePath?: Maybe<Scalars['String']>;
  simpleName?: Maybe<Scalars['String']>;
};


export type ContainerPropertyArgs = {
  key: Scalars['String'];
};

export type DhSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'DHSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<HierarchyEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  type: Scalars['String'];
};


export type DhSmellPropertyArgs = {
  key: Scalars['String'];
};

export type DateInterval = {
  __typename?: 'DateInterval';
  endDate: Scalars['String'];
  intervalInDays: Scalars['Int'];
  startDate: Scalars['String'];
};

export type DateIntervalInput = {
  endDate?: InputMaybe<Scalars['String']>;
  intervalInDays?: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type DependencyEdge = ArcanEdge & {
  __typename?: 'DependencyEdge';
  codeSnippet: CodeSnippet;
  dependant: Component;
  dependedUpon: Component;
  dependencyAmongContainers: Scalars['Boolean'];
  dependencyAmongUnits: Scalars['Boolean'];
  id: Scalars['Int'];
  inferSources?: Maybe<Array<Maybe<DependencyEdge>>>;
  inferred: Scalars['Boolean'];
  label: Scalars['String'];
  locationList: Array<DependencyLocation>;
  property?: Maybe<Scalars['String']>;
  weight: Scalars['Int'];
};


export type DependencyEdgePropertyArgs = {
  key: Scalars['String'];
};

export type DependencyGraph = {
  __typename?: 'DependencyGraph';
  HLEdges?: Maybe<Array<Maybe<HlEdges>>>;
  UDEdges?: Maybe<Array<Maybe<UdEdges>>>;
  affectsEdges?: Maybe<Array<Maybe<AffectsEdge>>>;
  allContainers?: Maybe<Array<Maybe<Container>>>;
  allUnits?: Maybe<Array<Maybe<Unit>>>;
  archSmells?: Maybe<Array<Maybe<ArchitecturalSmell>>>;
  dependencyEdges?: Maybe<Array<Maybe<DependencyEdge>>>;
  edgeSourcesTree: Array<EdgeSourcesTree>;
  edgeSourcesTreeVertex: Array<Array<EdgeSourcesTree>>;
  hierarchyEdges?: Maybe<Array<Maybe<HierarchyEdge>>>;
  inclusionEdges?: Maybe<Array<Maybe<InclusionEdge>>>;
  membershipEdges?: Maybe<Array<Maybe<MembershipEdge>>>;
  smellById?: Maybe<ArchitecturalSmell>;
  smellProperty?: Maybe<Scalars['String']>;
};


export type DependencyGraphArchSmellsArgs = {
  n?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type DependencyGraphEdgeSourcesTreeArgs = {
  dependencyEdgeId: Scalars['Int'];
};


export type DependencyGraphEdgeSourcesTreeVertexArgs = {
  vertexId: Scalars['Int'];
};


export type DependencyGraphSmellByIdArgs = {
  smellId: Scalars['Int'];
};


export type DependencyGraphSmellPropertyArgs = {
  propertyKey: Scalars['String'];
  smellId: Scalars['String'];
};

export type DependencyLocation = {
  __typename?: 'DependencyLocation';
  column: Scalars['Int'];
  columnEnd: Scalars['Int'];
  endLine: Scalars['Int'];
  line: Scalars['Int'];
  sourceType: DependencySourceType;
};

export type DependencySourceType = {
  __typename?: 'DependencySourceType';
  name: Scalars['String'];
};

export type DetectorsInput = {
  detectorNames: Array<Scalars['String']>;
};

export type DirTreeRoot = {
  __typename?: 'DirTreeRoot';
  dirTreeJson?: Maybe<Scalars['String']>;
  lastCommitDate?: Maybe<Scalars['String']>;
  lastCommitId?: Maybe<Scalars['String']>;
};

export type EdgeSourcesTree = {
  __typename?: 'EdgeSourcesTree';
  edge: DependencyEdge;
  parentId?: Maybe<Scalars['Int']>;
  root: Scalars['Boolean'];
  sources: Array<DependencyEdge>;
};

export type FileUploadResult = {
  __typename?: 'FileUploadResult';
  resourceId?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GcSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'GCSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  centre?: Maybe<Container>;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  linesOfCode?: Maybe<Scalars['Int']>;
  linesOfCodeMedian?: Maybe<Scalars['Int']>;
  numberOfDirectlyContainedElements?: Maybe<Scalars['Int']>;
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<MembershipEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  thresholdValueUsed?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type GcSmellPropertyArgs = {
  key: Scalars['String'];
};

export type HlEdges = ArcanEdge & {
  __typename?: 'HLEdges';
  archSmell?: Maybe<ArchitecturalSmell>;
  component?: Maybe<Component>;
  id: Scalars['Int'];
  label: Scalars['String'];
  property?: Maybe<Scalars['String']>;
};


export type HlEdgesPropertyArgs = {
  key: Scalars['String'];
};

export type HlSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'HLSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  centre?: Maybe<Component>;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  inDependencies?: Maybe<Array<Maybe<Component>>>;
  label: Scalars['String'];
  outDependencies?: Maybe<Array<Maybe<Component>>>;
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<DependencyEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  thresholdValueUsed?: Maybe<Scalars['Int']>;
  totalDependenciesCount?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type HlSmellPropertyArgs = {
  key: Scalars['String'];
};

export type HasInstantEdge = {
  __typename?: 'HasInstantEdge';
  age: Scalars['Int'];
  instant: InstantNode;
  version: Version;
};

export type HierarchyEdge = ArcanEdge & {
  __typename?: 'HierarchyEdge';
  children?: Maybe<Unit>;
  id: Scalars['Int'];
  label: Scalars['String'];
  parent?: Maybe<Unit>;
  property?: Maybe<Scalars['String']>;
};


export type HierarchyEdgePropertyArgs = {
  key: Scalars['String'];
};

export type IncludeMatcher = {
  __typename?: 'IncludeMatcher';
  globPattern: Scalars['String'];
  paths: Array<Scalars['String']>;
};

export type IncludeMatcherInput = {
  globPattern: Scalars['String'];
  paths: Array<Scalars['String']>;
};

export type InclusionEdge = ArcanEdge & {
  __typename?: 'InclusionEdge';
  header?: Maybe<Unit>;
  id: Scalars['Int'];
  implementation?: Maybe<Unit>;
  label: Scalars['String'];
  property?: Maybe<Scalars['String']>;
};


export type InclusionEdgePropertyArgs = {
  key: Scalars['String'];
};

export type InstantNode = {
  __typename?: 'InstantNode';
  instantEdge: HasInstantEdge;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  version: Version;
};


export type InstantNodePropertyArgs = {
  key: Scalars['String'];
};

export type JobInfo = {
  __typename?: 'JobInfo';
  analysisSummary?: Maybe<AnalysisSummary>;
  description?: Maybe<Scalars['String']>;
  ended?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  jobGroup: Scalars['String'];
  jobName?: Maybe<Scalars['String']>;
  raisedExceptionMessage?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['String']>;
  startedBy?: Maybe<Scalars['String']>;
  status: JobStatus;
  targetResourceId: Scalars['Int'];
};

export type JobStatus = {
  __typename?: 'JobStatus';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LcdSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'LCDSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  isMultiLayer?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  layer: Array<Component>;
  layers: Array<Array<Component>>;
  layersCount?: Maybe<Scalars['Int']>;
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  shape?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<DependencyEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  topLayer: Array<Component>;
  type: Scalars['String'];
};


export type LcdSmellLayerArgs = {
  depth: Scalars['Int'];
};


export type LcdSmellPropertyArgs = {
  key: Scalars['String'];
};

export type Licence = {
  __typename?: 'Licence';
  limitations: Array<Limitation>;
  owner: Scalars['String'];
};

export type Limitation = {
  __typename?: 'Limitation';
  limit: Scalars['String'];
  name: Scalars['String'];
  userString: Scalars['String'];
};

export type MembershipEdge = ArcanEdge & {
  __typename?: 'MembershipEdge';
  id: Scalars['Int'];
  label: Scalars['String'];
  member?: Maybe<Component>;
  parent?: Maybe<Component>;
  property?: Maybe<Scalars['String']>;
};


export type MembershipEdgePropertyArgs = {
  key: Scalars['String'];
};

export type MetricsConfiguration = {
  __typename?: 'MetricsConfiguration';
  componentMetrics: Array<AlgorithmDescriptor>;
  smellCharacteristics: Array<AlgorithmDescriptor>;
};

export type MetricsInput = {
  componentMetricNames: Array<Scalars['String']>;
  smellCharacteristicNames: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  analyseProjectById: JobInfo;
  analyseProjectJob: JobInfo;
  newProject: Scalars['Int'];
  newProjectJob: JobInfo;
  newProjectUpload?: Maybe<FileUploadResult>;
  removeProject: Scalars['Boolean'];
  removeVersion: Scalars['Boolean'];
  renameProject: Scalars['Boolean'];
  updateProject: Scalars['Boolean'];
};


export type MutationAnalyseProjectByIdArgs = {
  projectId: Scalars['Int'];
};


export type MutationAnalyseProjectJobArgs = {
  analysisOptions: AnalysisOptionsInput;
};


export type MutationNewProjectArgs = {
  project: ProjectInput;
};


export type MutationNewProjectJobArgs = {
  project: ProjectInput;
};


export type MutationNewProjectUploadArgs = {
  project: ProjectInput;
  upload: Scalars['Upload'];
};


export type MutationRemoveProjectArgs = {
  projectId: Scalars['Int'];
};


export type MutationRemoveVersionArgs = {
  projectId: Scalars['Int'];
  versionId?: InputMaybe<Scalars['String']>;
};


export type MutationRenameProjectArgs = {
  newProjectName: Scalars['String'];
  projectId: Scalars['Int'];
};


export type MutationUpdateProjectArgs = {
  analysisOptions: AnalysisOptionsInput;
};

export type PathFilter = {
  __typename?: 'PathFilter';
  excludeGlobPatterns: Array<Scalars['String']>;
  includeGlobPatterns: Array<Scalars['String']>;
};

export type PathFilterInput = {
  excludeGlobPatterns: Array<Scalars['String']>;
  includeGlobPatterns: Array<Scalars['String']>;
};

export enum ProgrammingLanguage {
  C = 'C',
  Cpp = 'CPP',
  Csharp = 'CSHARP',
  Java = 'JAVA',
  Python = 'PYTHON'
}

export type Project = {
  __typename?: 'Project';
  analysisConfiguration: AnalysisConfiguration;
  dependencyGraph: DependencyGraph;
  dirTreeRoot?: Maybe<DirTreeRoot>;
  id: Scalars['Int'];
  lastAnalysedVersion?: Maybe<Version>;
  name: Scalars['String'];
  orientName: Scalars['String'];
  programmingLanguage: Scalars['String'];
  projectAnalysisStatus: ProjectAnalysisStatus;
  resultsSummary?: Maybe<ProjectResultsSummary>;
  temporalGraph: TemporalGraph;
  temporalStatistics: TemporalStatistics;
  version?: Maybe<Version>;
  versions: Array<Version>;
};


export type ProjectDependencyGraphArgs = {
  versionId?: InputMaybe<Scalars['String']>;
};


export type ProjectDirTreeRootArgs = {
  includeNonMatchingSubTree?: InputMaybe<Scalars['Boolean']>;
  searchString?: InputMaybe<Scalars['String']>;
};


export type ProjectVersionArgs = {
  versionId?: InputMaybe<Scalars['String']>;
};

export enum ProjectAnalysisStatus {
  Free = 'FREE',
  NeverAnalyzed = 'NEVER_ANALYZED',
  Running = 'RUNNING'
}

export type ProjectInput = {
  language: ProgrammingLanguage;
  name: Scalars['String'];
  projectLocations: Array<ProjectLocationInput>;
  versionControlSystem?: VersionControlSystems;
};

export type ProjectLocationInput = {
  authPassword?: InputMaybe<Scalars['String']>;
  authUsername?: InputMaybe<Scalars['String']>;
  branch?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  remoteURL?: InputMaybe<Scalars['String']>;
  uploadedZip?: InputMaybe<Scalars['Upload']>;
};

export type ProjectNode = {
  __typename?: 'ProjectNode';
  allInstantsSorted: Array<InstantNode>;
};

export type ProjectResultsSummary = {
  __typename?: 'ProjectResultsSummary';
  linesOfCode?: Maybe<Scalars['String']>;
  numberOfSmells?: Maybe<Scalars['String']>;
  techDebtIndex?: Maybe<Scalars['String']>;
  techDebtIndexHistory?: Maybe<Array<Scalars['String']>>;
  techDebtRating?: Maybe<Scalars['String']>;
  techDebtScore?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allJobs: Array<JobInfo>;
  analysisAlgorithmNames: Array<AlgorithmNames>;
  demoMode?: Maybe<Scalars['Boolean']>;
  jobInfo?: Maybe<JobInfo>;
  licence: Licence;
  listProjects?: Maybe<Array<Maybe<Project>>>;
  projectById?: Maybe<Project>;
};


export type QueryAllJobsArgs = {
  runningOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryJobInfoArgs = {
  id: Scalars['String'];
};


export type QueryProjectByIdArgs = {
  projectId: Scalars['Int'];
};

export type SeriesPoint = {
  __typename?: 'SeriesPoint';
  group: Scalars['String'];
  seriesName: Scalars['String'];
  value: Scalars['String'];
  version: Version;
};

export type SmellDetectorsConfiguration = {
  __typename?: 'SmellDetectorsConfiguration';
  smellDetectors: Array<AlgorithmDescriptor>;
};

export type SmellEntityNode = {
  __typename?: 'SmellEntityNode';
  affectedComponentType: Scalars['String'];
  affectsEdges: Array<TemporalAffectsEdge>;
  age: Scalars['Int'];
  ageInVersion: Scalars['Int'];
  allInstants: Array<Maybe<InstantNode>>;
  id: Scalars['Int'];
  label: Scalars['String'];
  lastInstant?: Maybe<InstantNode>;
  smellType: Scalars['String'];
};


export type SmellEntityNodeAgeInVersionArgs = {
  versionId: Scalars['String'];
  versionIndex: Scalars['Int'];
};

export type SmellGraph = {
  __typename?: 'SmellGraph';
  HLEdges?: Maybe<Array<Maybe<HlEdges>>>;
  UDEdges?: Maybe<Array<Maybe<UdEdges>>>;
  affectsEdges?: Maybe<Array<Maybe<AffectsEdge>>>;
  dependencyEdges?: Maybe<Array<Maybe<DependencyEdge>>>;
  hierarchyEdges?: Maybe<Array<Maybe<HierarchyEdge>>>;
  membershipEdges?: Maybe<Array<Maybe<MembershipEdge>>>;
};

export type TemporalAffectsEdge = {
  __typename?: 'TemporalAffectsEdge';
  affectedEntity: ComponentEntityNode;
  affectedEntityCorrespondingInstant?: Maybe<InstantNode>;
  affectingEntity: SmellEntityNode;
  affectingEntityCorrespondingInstant?: Maybe<InstantNode>;
  id: Scalars['Int'];
  version: Version;
};

export type TemporalGraph = {
  __typename?: 'TemporalGraph';
  affectsEdges: Array<TemporalAffectsEdge>;
  allComponents: Array<Maybe<ComponentEntityNode>>;
  allSmells: Array<Maybe<SmellEntityNode>>;
  componentPropertyKeys?: Maybe<Scalars['String']>;
  projectNode: ProjectNode;
  smellPropertyKeys?: Maybe<Scalars['String']>;
};

export type TemporalStatistics = {
  __typename?: 'TemporalStatistics';
  historical?: Maybe<TimeSeries>;
  trends?: Maybe<Trends>;
};


export type TemporalStatisticsHistoricalArgs = {
  startingVersionId?: InputMaybe<Scalars['String']>;
  untilVersionId?: InputMaybe<Scalars['String']>;
};


export type TemporalStatisticsTrendsArgs = {
  versionId?: InputMaybe<Scalars['String']>;
};

export type TimeSeries = {
  __typename?: 'TimeSeries';
  changedCleanPercentage: Array<SeriesPoint>;
  changedCount: Array<SeriesPoint>;
  changedSmellyPercentage: Array<SeriesPoint>;
  linesOfCode: Array<SeriesPoint>;
  numberOfContainers: Array<SeriesPoint>;
  numberOfSmells: Array<SeriesPoint>;
  numberOfUnits: Array<SeriesPoint>;
  rigidity: Array<SeriesPoint>;
  spaghettiDesignRatio: Array<SeriesPoint>;
  techDebtConcentrationName: Array<SeriesPoint>;
  techDebtCoverage: Array<SeriesPoint>;
  techDebtDispersionMetric: Array<SeriesPoint>;
  techDebtHours: Array<SeriesPoint>;
  techDebtIndex: Array<SeriesPoint>;
  techDebtRating: Array<SeriesPoint>;
  techDebtScore: Array<SeriesPoint>;
  techDebtSources: Array<SeriesPoint>;
  unchangedCount: Array<SeriesPoint>;
};

export enum Trend {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export type TrendDataPoint = {
  __typename?: 'TrendDataPoint';
  stringValue: Scalars['String'];
  trend: Trend;
  trendPercentage: Scalars['Float'];
  value: Scalars['Float'];
};

export type Trends = {
  __typename?: 'Trends';
  linesOfCode: TrendDataPoint;
  numberOfContainers: TrendDataPoint;
  numberOfSmells: TrendDataPoint;
  numberOfUnits: TrendDataPoint;
  rigidity: TrendDataPoint;
  spaghettiDesignRatio: TrendDataPoint;
  techDebtConcentrationName: Scalars['String'];
  techDebtCoverage: TrendDataPoint;
  techDebtDispersionMetric: TrendDataPoint;
  techDebtHours: TrendDataPoint;
  techDebtIndex: TrendDataPoint;
  techDebtRating: TrendDataPoint;
  techDebtScore: TrendDataPoint;
};

export type UdEdges = ArcanEdge & {
  __typename?: 'UDEdges';
  archSmell?: Maybe<ArchitecturalSmell>;
  container?: Maybe<Container>;
  id: Scalars['Int'];
  label: Scalars['String'];
  property?: Maybe<Scalars['String']>;
};


export type UdEdgesPropertyArgs = {
  key: Scalars['String'];
};

export type UdSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'UDSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  badDependencies?: Maybe<Array<Maybe<Container>>>;
  cardInfo: ArchSmellCardInfo;
  centre?: Maybe<Container>;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  instabilityGap?: Maybe<Scalars['Float']>;
  instabilityGapThreshold?: Maybe<Scalars['Float']>;
  label: Scalars['String'];
  numberOfLessStableElements?: Maybe<Scalars['Int']>;
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<DependencyEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  strength?: Maybe<Scalars['Float']>;
  strengthThreshold?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
};


export type UdSmellPropertyArgs = {
  key: Scalars['String'];
};

export type Unit = Component & {
  __typename?: 'Unit';
  afferentContainers?: Maybe<Array<Maybe<Container>>>;
  connectedSmells?: Maybe<Array<Maybe<ArchitecturalSmell>>>;
  constructType: ConstructType;
  container?: Maybe<Container>;
  getExtendedUnits?: Maybe<Array<Maybe<Unit>>>;
  getNestedUnits?: Maybe<Array<Maybe<Unit>>>;
  id: Scalars['Int'];
  implementedUnits?: Maybe<Array<Maybe<Unit>>>;
  incomingDependencies?: Maybe<Array<Maybe<Unit>>>;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nestedUnits?: Maybe<Array<Maybe<Unit>>>;
  outgoingDependencies?: Maybe<Array<Maybe<Unit>>>;
  parent?: Maybe<Component>;
  parentOfNestedUnit?: Maybe<Unit>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  realFilePath?: Maybe<Scalars['String']>;
  relativeFilePath?: Maybe<Scalars['String']>;
  simpleName?: Maybe<Scalars['String']>;
};


export type UnitPropertyArgs = {
  key: Scalars['String'];
};

export type Version = {
  __typename?: 'Version';
  isSuccessfullyAnalysed: Scalars['Boolean'];
  shortenedVersionId: Scalars['String'];
  versionDate: Scalars['String'];
  versionId: Scalars['String'];
  versionIndex: Scalars['Int'];
  versionTag?: Maybe<Scalars['String']>;
};

export enum VersionControlSystems {
  Git = 'GIT',
  NoVcs = 'NO_VCS',
  Svn = 'SVN'
}

export type WhSmell = ArcanVertex & ArchitecturalSmell & {
  __typename?: 'WHSmell';
  affectedComponents?: Maybe<Array<Maybe<Component>>>;
  affectedComponentsNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  affectedConstructType: ConstructType;
  affectedType?: Maybe<Scalars['String']>;
  cardInfo: ArchSmellCardInfo;
  extendedSmellGraph?: Maybe<SmellGraph>;
  fullTypeName: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  pageRank?: Maybe<Scalars['Float']>;
  properties?: Maybe<Array<Maybe<KeyValuePair>>>;
  property?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  smellEdges?: Maybe<Array<Maybe<HierarchyEdge>>>;
  smellGraph?: Maybe<SmellGraph>;
  type: Scalars['String'];
};


export type WhSmellPropertyArgs = {
  key: Scalars['String'];
};
