import React, { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ListItemIcon } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CheckIcon from '@mui/icons-material/Check'

export interface SimpleListMenuProps {
	title: string
	selected: number
	options: {
		label: string
		checked: boolean
	}[]
	onChange: (index: number) => void
	menuclass?: 'clean'
}

const SimpleListMenu = ({
	title,
	selected,
	options,
	menuclass,
	onChange,
}: SimpleListMenuProps) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLElement>,
		index: number
	) => {
		onChange(index)
		setAnchorEl(null)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<div className='subheadernav'>
			<List component='nav' aria-label='Device settings' className={menuclass}>
				<ListItem
					button
					id='lock-button'
					aria-haspopup='listbox'
					aria-controls='lock-menu'
					aria-label={title.toLowerCase()}
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClickListItem}
					className='title'
				>
					<KeyboardArrowDownIcon className='iconexp'></KeyboardArrowDownIcon>
					<ListItemText
						primary={title}
						secondary={options[selected]?.label ?? ''}
					/>
				</ListItem>
			</List>
			<Menu
				id='lock-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'lock-button',
					role: 'listbox',
				}}
			>
				{options?.map((option: any, index: any) => (
					<MenuItem
						key={option.label}
						selected={index === selected}
						onClick={(event) => handleMenuItemClick(event, index)}
					>
						{option.label}
						{'  '}
						{option.checked ? (
							<CheckIcon sx={{ fontSize: '18px' }}></CheckIcon>
						) : (
							''
						)}
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}

export default SimpleListMenu
