import {
  AppBar,
  styled,
  Container,
  Toolbar,
  Typography,
  Button,
  IconButton
} from '@mui/material'
import React, {Fragment, FunctionComponent} from 'react'
import Overview from '../../img/icons/Overview.png'

const SubHeaderContainer = styled(AppBar)(({ theme }) => ({
  background: theme.customPalette.subheaderBackground,
  color: theme.customPalette.text,
}))

interface ISubHeader {
  title: string | React.ReactElement
  buttons?: {
    text: string
    variant: 'text' | 'contained' | 'outlined'
    hidden?: boolean
    disabled?: boolean
    onClick?: () => void
  }[]
}

const SubHeader: FunctionComponent<ISubHeader> = ({ title, buttons }) => {
  return (
    <SubHeaderContainer position='relative'>
      <Container maxWidth={false}>
        <Toolbar disableGutters sx={{ flexWrap: 'wrap' }}>
          {typeof title === 'string' ? (
            <Typography fontSize={26} fontFamily='Karla' sx={{ flexGrow: 1 }}>
              {title}
            </Typography>
          ) : (
            title
          )}
          <div>
            {buttons?.map((btn, i) => 
            btn.hidden? <Fragment key={i}></Fragment> : (
              <Button
                key={i}
                variant={btn.variant}
                color='secondary'
                onClick={btn.onClick}
                disabled={btn.disabled ? btn.disabled : false}
                sx={{ marginLeft: '.5rem' }}
              >
                {btn.text}
              </Button>
            ))}
          </div>
        </Toolbar>
      </Container>
    </SubHeaderContainer>
  )
}

export default SubHeader
