import {Box, CardContent} from '@mui/material'
import Chart from 'Components/Chart/Chart'

interface ICardDebt{
    technicalDebt:{
        chart?:{
            title?:string
            data:any
        }
    }
    
}

const CardDebt =({technicalDebt}:ICardDebt) =>{
return(
    <Box>
        <CardContent className='cardTechDebt'>
            {technicalDebt.chart?(
                <>
                    <h6 style={{fontSize:'16px', color:'#ff3399', textAlign:'left', margin:'0 auto', paddingBottom:'2%'}}>{technicalDebt.chart.title}</h6>
                    <Chart options={technicalDebt.chart.data} />
                </>
            ):null}
        </CardContent>
    </Box>
)
}

export default CardDebt;