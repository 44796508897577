import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  AlgorithmNames,
  JobInfo,
  Project,
  ProjectInput
} from 'Model/arcan-types'

interface IGetAllProjects {
  listProjects: Project[]
  demoMode: boolean
}

export const GET_ALL = gql`
  query {
    listProjects {
      id
      name
      programmingLanguage
      versions {
        versionId
        versionDate
        versionIndex
        versionTag
        shortenedVersionId
      }
      lastAnalysedVersion {
        versionId
        versionDate
        versionIndex
        versionTag
        shortenedVersionId
      }
      resultsSummary{
        techDebtScore
        linesOfCode
        techDebtIndex
        techDebtRating
        numberOfSmells
        techDebtIndexHistory
      }
    }
    demoMode
  }
`

export const useGetAllProjects = () => {
  const { loading, data, refetch } = useQuery<IGetAllProjects>(GET_ALL, {
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
    },
  })

  return {
    loading,
    projects: data?.listProjects ?? [],
    demoMode: data?.demoMode ?? false,
    refetch,
  }
}

interface IGetAlgorithmNames {
  analysisAlgorithmNames: AlgorithmNames[]
}

export const GET_ALGORITHM_NAMES = gql`
  query {
    analysisAlgorithmNames {
      typeName
      names {
        key
        value
      }
    }
  }
`

export const useGetAlgorithmNames = () => {
  const { loading, data } = useQuery<IGetAlgorithmNames>(GET_ALGORITHM_NAMES)

  return {
    loading,
    algorithms: data?.analysisAlgorithmNames ?? [],
  }
}

interface ICreateProject {
  newProjectJob: JobInfo
}

export const CREATE_PROPJECT = gql`
  mutation createProject($project: ProjectInput!) {
    newProjectJob(project: $project) {
      id
      jobGroup
      jobName
      description
      status {
        name
        description
      }
      raisedExceptionMessage
      startedBy
      started
      ended
      targetResourceId
    }
  }
`

export const useMutationCreateProject = () =>
  useMutation<ICreateProject, { project: ProjectInput }>(CREATE_PROPJECT)

interface IGetDirTree {
  projectById: Project
}

export const DIR_TREE = gql`
  query dirTree($projectId: Int!) {
    projectById(projectId: $projectId) {
      name
      programmingLanguage
      dirTreeRoot {
            lastCommitId
            lastCommitDate
            dirTreeJson
        }
    }
  }
`

export const useGetLazyTree = () =>
  useLazyQuery<IGetDirTree, { projectId: number }>(DIR_TREE)

interface IGetOverview {
  projectById: Project
}

export const CORE_TREND_DATA_POINT = gql`
  fragment trendDataInfo on TrendDataPoint {
    value
    trendPercentage
    trend
  }
`

export const PROJECT_OVERVIEW = gql`
  ${CORE_TREND_DATA_POINT}
  query getProjectOverview($projectId: Int!, $versionId: String!) {
    projectById(projectId: $projectId) {
      name
      temporalStatistics {
        trends(versionId: $versionId) {
          techDebtConcentrationName
          techDebtDispersionMetric {
            ...trendDataInfo
          }
          techDebtIndex {
            ...trendDataInfo
          }
          techDebtHours {
            ...trendDataInfo
          }
          techDebtCoverage {
            ...trendDataInfo
          }
          numberOfSmells{
            ...trendDataInfo
          }
          numberOfUnits {
            ...trendDataInfo
          }
          numberOfContainers {
            ...trendDataInfo
          }
          spaghettiDesignRatio {
            ...trendDataInfo
          }
          linesOfCode {
            ...trendDataInfo
          }
          rigidity {
            ...trendDataInfo
          }
          techDebtRating {
            ...trendDataInfo
          }
          techDebtScore {
            ...trendDataInfo
          }
        }
        historical {
          numberOfSmells{
            group
            seriesName
            value
            version {
              versionId
              versionIndex
            }
          }
          techDebtSources{
            group
            seriesName
            value
            version {
              versionId
              versionIndex
            }
          }
          techDebtIndex {
            version {
              versionId
              versionIndex
              versionDate
              versionTag
              shortenedVersionId
              isSuccessfullyAnalysed
            }
            group
            seriesName
            value
          }
        }
      }
    }
  }
`

export const useGetOverviewLazy = () =>
  useLazyQuery<IGetOverview, { projectId: number; versionId?: string }>(
    PROJECT_OVERVIEW
  )

interface IDeleteProject {
  removeProject: boolean
}

export const DELETE_PROJECT = gql`
  mutation removeProject($projectId: Int!) {
    removeProject(projectId: $projectId)
  }
`

export const useDeleteProject = () =>
  useMutation<IDeleteProject, { projectId: number }>(DELETE_PROJECT)

export interface IProjectConfiguration {
  projectById: Project
}

export const GET_PROJECT_CONFIGURATION = gql`
  query getProject($projectId: Int!) {
    projectById(projectId: $projectId) {
      programmingLanguage
      analysisConfiguration {
        metrics {
          componentMetrics {
            name
            fullName
          }
          smellCharacteristics {
            name
            fullName
          }
        }
        detectors {
          smellDetectors {
            name
            fullName
          }
        }
        includeMatchers {
          globPattern
          paths
        }
        sourcePathMatchers {
          includeGlobPatterns
          excludeGlobPatterns
        }
      }
    }
  }
`

export const useGetAnalysisConfigLazy = () =>
  useLazyQuery<IProjectConfiguration, { projectId: number }>(
    GET_PROJECT_CONFIGURATION,
    {
      defaultOptions: {
        fetchPolicy: 'cache-and-network',
      },
    }
  )

interface IGetProject {
  projectById: Project
}

export const GET_PROJECT = gql`
  query getProject($projectId: Int!) {
    projectById(projectId: $projectId) {
      name
      programmingLanguage
      lastAnalysedVersion {
        versionId
        versionDate
        versionIndex
        versionTag
        shortenedVersionId
      }
      versions {
        versionId
        versionIndex
        versionDate
        versionTag
        shortenedVersionId
        isSuccessfullyAnalysed
      }
    }
  }
`

export const useGetProjectLazy = () =>
  useLazyQuery<IGetProject, { projectId: number }>(GET_PROJECT)

interface IAllJobs {
  allJobs: JobInfo[]
}

export const GET_ALL_JOBS = gql`
  query getAllJobs {
    allJobs(runningOnly: true) {
      description
      ended
      id
      jobGroup
      jobName
      started
      startedBy
      status {
        name
        description
      }
      targetResourceId
    }
  }
`

export const useGetJobs = () => {
  const { data, loading } = useQuery<IAllJobs>(GET_ALL_JOBS, {
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
    },
  })

  return {
    loading,
    jobs: data?.allJobs ?? ([] as JobInfo[]),
  }
}

export const useGetJobsLazy = () =>
  useLazyQuery<IAllJobs>(GET_ALL_JOBS, {
    defaultOptions: {
      fetchPolicy: 'cache-and-network',
    },
    pollInterval: 3000,
  })

interface ICreateProjectUpload {
  newProjectUpload: {
    success: boolean
    resourceId: number
  }
}

export const useMutationCreateProjectUpload = () => 
  useMutation<ICreateProjectUpload, { project: ProjectInput, file: File }>(CREATE_PROJECT_UPLOAD)


export const CREATE_PROJECT_UPLOAD = gql`
  mutation createProjectUpload($project: ProjectInput!, $file: Upload!) {
    newProjectUpload(project: $project, upload: $file){
      success
      resourceId
    }
  }
`