import { EChartOption } from 'echarts'


export const getDefaultDonut= (data: {value:number, name: string}[], colors: string[]) =>({
    tooltip: {
        trigger: 'item',
    },
    legend :{
                left:'center',
                textStyle: {
                    color:'#FBF9FF',
                    fontWeight:'bold'
                }
            },
    series: [
        {
            name: 'Type',
            type: 'pie',
            radius: ['40%', '70%'],
            color: colors,
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
            },
            label: {
                show: false,
                position: 'center',
                fontWeight:'bold',
                color:'#FBF9FF'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: 16,
                    color:'#FBF9FF'
                }
            },
            labelLine: {
                show: false
            },
            data: data
        },
        
                
    ]
} as any as EChartOption)