import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import WithLoading from 'Components/HOC/WithLoading'
import { useGetTreeDGLazy } from 'Queries/DependencyGraphq'
import { DependencyGraph } from 'Templates/DependencyGraph'
import {useLazyGetSmellByEi, useLazyGetSmellByVi} from "Queries/Smells";
import {DependencyEdge} from "Model/arcan-types";

const DependecyGraphWithLoading = WithLoading(DependencyGraph)

const DependecyGraphData = () => {
  const { projectId, versionId } =
    useParams<{ projectId: string; versionId: string }>()
  const [getTreeDG, { loading, data }] = useGetTreeDGLazy()
  const [Edges, setEdges] = useState<DependencyEdge[]>([])
  const [getEdgeByVi, { loading: loadingVi, data: dataVi }] =
      useLazyGetSmellByVi()
  const [getEdgeByEi, { loading: loadingEi, data: dataEi }] =
      useLazyGetSmellByEi()
  const [tableTitle, setTableTitle] = useState('')

  useEffect(() => {
    if (projectId && versionId) {
      getTreeDG({
        variables: {
          projectId: parseInt(projectId),
          versionId,
        },
      })
      setEdges([])
    }
  }, [projectId, versionId])

  useEffect(() => {
    if (dataVi?.projectById) {
      const { edgeSourcesTreeVertex } = dataVi.projectById.dependencyGraph

      var flattedSources = edgeSourcesTreeVertex.flat()

      var edges = flattedSources
          .filter((edgeSource) => edgeSource.edge.dependencyAmongUnits)
          .map((edgeSource) => edgeSource.edge)
      edges = edges.concat(
          flattedSources
              .filter((edge) => edge.sources.length)
              .map((edge) => edge.sources)
              .flat()
      )

      setEdges(edges)
    }

  }, [dataVi])

  useEffect(() => {
    if (dataEi?.projectById) {
      const { edgeSourcesTree } = dataEi.projectById.dependencyGraph

      var flattedSources = edgeSourcesTree.flat()

      var edges = flattedSources
          .filter((edgeSource) => edgeSource.edge.dependencyAmongUnits)
          .map((edgeSource) => edgeSource.edge)
      edges = edges.concat(
          flattedSources
              .filter((edge) => edge.sources.length)
              .map((edge) => edge.sources)
              .flat()
      )

      setEdges(edges)
    }
  }, [dataEi])


  return (
    <DependecyGraphWithLoading
      loading={loading}
      showHeader={false}
      dependencyGraph={data?.projectById?.dependencyGraph}
      edges={Edges}
      tableTitle={tableTitle}
      handleSelectItem={(item, type) => {
        switch (type) {
          case 'edge':
            getEdgeByEi({
              variables: {
                projectId: parseInt(projectId!),
                versionId: versionId!,
                dependencyEdgeId: item.data().id,
              },
            })
            setTableTitle(
                `Dependency sources: ${(item as any).source().data().name} -> ${
                    (item as any).target().data().name
                }`
            )
            break
          case 'node':
            getEdgeByVi({
              variables: {
                projectId: parseInt(projectId!),
                versionId: versionId!,
                vertexId: item.data().id,
              },
            })
            setTableTitle(`Dependency sources: ${item.data().name}`)
            break
        }
      }}
    />
  )
}

export default DependecyGraphData
