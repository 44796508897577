import React, { useEffect, useRef, useState } from 'react'
import cytoscape, {
  CollectionReturnValue,
  EdgeDefinition,
  LayoutOptions,
  NodeCollection,
  NodeDefinition,
} from 'cytoscape'
import {
  Box,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CachedIcon from '@mui/icons-material/Cached'
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import GraphEditorSelect from '../Form/GraphEditor/GraphEditorSelect'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import klay from 'cytoscape-klay'

cytoscape.use(klay)

let timeout: NodeJS.Timeout

const LAYOUTS = [
  {
    value: 'random',
    text: 'Random',
  },
  {
    value: 'grid',
    text: 'Grid',
  },
  {
    value: 'circle',
    text: 'Circle',
  },
  {
    value: 'concentric',
    text: 'Concentric',
  },
  {
    value: 'klay',
    text: 'Klay',
  },
]

const applyLayout = (
  nodes: NodeCollection,
  component: HTMLDivElement | null,
  layoutName: string
) => {
  if (component && layoutName) {
    var layout: cytoscape.Layouts
    switch (layoutName) {
      case 'concentric':
        layout = nodes.layout({
          name: layoutName,
          animate: false,
          minNodeSpacing: 128,
          boundingBox: {
            x1: 0,
            y1: 0,
            x2: component?.clientWidth ?? 0,
            y2: component?.clientHeight ?? 0,
          },
        } as LayoutOptions)
        break
      case 'klay':
        layout = nodes.layout({
          name: layoutName,
          animate: false,
          spacingFactor: Math.round(1 + 100 / nodes.length),
          boundingBox: {
            x1: 0,
            y1: 0,
            x2: component?.clientWidth ?? 0,
            y2: component?.clientHeight ?? 0,
          },
        } as LayoutOptions)
        break
      default:
        layout = nodes.layout({
          name: layoutName,
          animate: false,
          idealEdgeLength: () => 128,
          boundingBox: {
            x1: 0,
            y1: 0,
            x2: component?.clientWidth ?? 0,
            y2: component?.clientHeight ?? 0,
          },
        } as LayoutOptions)
        break
    }

    layout.run()
  }
}

const StyledSearch = styled(TextField)(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    color: theme.customPalette.text,
  },
}))

export interface IBaseGraph {
  nodes: NodeDefinition[]
  edges: EdgeDefinition[]
  graphLayout: {
    nodeLabel: string
    edgeLabel: string
  }
  tapHandler: cytoscape.EventHandler
  actions?: {
    icon: JSX.Element
    action: () => void
    tooltip: string
  }[]
  setCyCoreExt?: (arg0: cytoscape.Core) => void
  nodeIdToCenter?: string
  tapEdgeHandler?: cytoscape.EventHandler
  defaultLayout?: string
  enableDepth?: boolean
  onChangeDepth?: (arg0: string) => void
}

const BaseGraph = ({
  nodes,
  edges,
  graphLayout,
  tapHandler,
  actions,
  setCyCoreExt,
  nodeIdToCenter,
  tapEdgeHandler,
  defaultLayout,
  enableDepth = false,
  onChangeDepth,
}: IBaseGraph) => {
  const [CyCore, setCyCore] = useState<cytoscape.Core>()
  const [DisableZoom, setDisableZoom] = useState(true)
  const [Search, setSearch] = useState<string | null>(null)
  const [SelectedLayout, setSelecteLayout] = useState('random')
  const [NodesFound, setNodesFound] = useState<cytoscape.NodeSingular[]>([])
  const [NodeFoundIndex, setNodeFoundIndex] = useState(0)

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef.current && !CyCore) {
      var cy = cytoscape({
        container: divRef.current, // container to render in
        // If you want to apply the layout on the constructor
        // you must supply the elements too
        elements: {
          nodes: [],
          edges: [],
        },
        zoom: 0.5,
        minZoom: 0.1,
        maxZoom: 5,
      })
      cy.on('tap', 'node', tapHandler)
      if (tapEdgeHandler) cy.on('tap', 'edge', tapEdgeHandler)

      setCyCore(cy)
      if (setCyCoreExt) setCyCoreExt(cy)
    }
  }, [divRef, setCyCoreExt])

  useEffect(() => {
    if (defaultLayout) {
      setSelecteLayout(defaultLayout)
    }
  }, [defaultLayout])

  useEffect(() => {
    if (CyCore) {
      const { nodeLabel, edgeLabel } = graphLayout
      CyCore.style([
        {
          selector: 'node',
          style: {
            color: 'silver',
            label:
              nodeLabel && nodeLabel !== 'none' ? `data(${nodeLabel})` : '',
            'background-color': 'data(backgroundColor)',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 3,
            'target-arrow-color': 'data(lineColor)',
            'target-arrow-shape': 'triangle',
            'curve-style': 'bezier',
            color: 'silver',
            label:
              edgeLabel && edgeLabel !== 'none' ? `data(${edgeLabel})` : '',
            'line-color': 'data(lineColor)',
          },
        },
        {
          selector: '.highlighted',
          style: {
            "padding-bottom": "10px",
            'border-color': '#ff3399',
            'border-width': '6px',
            'color': '#ff3399',
            "z-index": 9999,
            label:
                nodeLabel && nodeLabel !== 'none' ? `data(${nodeLabel})` : '',
            'background-color': 'data(backgroundColor)',
          },
        }
      ])
    }
  }, [graphLayout, CyCore])

  useEffect(() => {
    if (CyCore) {
      const newNodes = [...nodes]
      const newEdges = [...edges]

      const currentNodes = CyCore.nodes()
      currentNodes.forEach(node => {
        const newNodeIndex = newNodes.findIndex(
          n => n.data.id === node.data('id')
        )
        if (newNodeIndex !== -1) {
          const newNode = newNodes.splice(newNodeIndex, 1)
          node.data(newNode[0].data)
        } else {
          node.remove()
        }
      })

      let newNodeAdded: CollectionReturnValue | null = null
      if (newNodes.length) {
        newNodeAdded = CyCore.add(newNodes)
      }

      const currentEdges = CyCore.edges()
      currentEdges.forEach(edge => {
        const newEdgeIndex = newEdges.findIndex(
          e => e.data.id === edge.data('id')
        )
        if (newEdgeIndex !== -1) {
          const newEdge = newEdges.splice(newEdgeIndex, 1)
          edge.data(newEdge[0].data)
        } else edge.remove()
      })

      let newEdgesAdded: CollectionReturnValue | null = null
      if (newEdges.length) {
        newEdgesAdded = CyCore.add(newEdges)
      }

      if (nodeIdToCenter) {
        const node = CyCore.$(`#${nodeIdToCenter}`)
        node.position({ x: CyCore.width() / 2, y: CyCore.height() / 2 })
      }

      if (newNodeAdded !== null) {
        applyLayout(newNodeAdded, divRef.current, SelectedLayout)
        setTimeout(() => {
          CyCore.fit()
        }, 200)
      }
    }
  }, [CyCore, nodes, edges, divRef, SelectedLayout, nodeIdToCenter])

  useEffect(() => {
    if (CyCore) {
      CyCore.userZoomingEnabled(!DisableZoom)
    }
  }, [DisableZoom, CyCore])

  useEffect(() => {
    if (Search != null && CyCore) {
      const { nodeLabel } = graphLayout
      if (Search === '') {
        CyCore.fit()
        setNodesFound([])
        return
      }

      const nodes = CyCore.nodes()
        .toArray()
        .filter(n =>
          n.data(nodeLabel).toLowerCase().includes(Search.toLowerCase())
        )

      setNodesFound(nodes)
      setNodeFoundIndex(0)
    }
  }, [CyCore, Search, graphLayout])

  useEffect(() => {
    if (!CyCore) return

    NodesFound.forEach(node => {
      node.removeClass('highlighted')
      node.addClass('node')
    })
    if (NodesFound.length) {
      const node = NodesFound[NodeFoundIndex]
      CyCore.center(node)
      CyCore.zoom({
        level: 2.0,
        position: node.position(),
      })
      node.removeClass('node')
      node.addClass('highlighted')

    } else {
      CyCore.fit()
    }
  }, [CyCore, NodesFound, NodeFoundIndex])

  useEffect(() => {
    if (divRef.current && SelectedLayout && CyCore && CyCore.elements()) {
      applyLayout(CyCore.elements(), divRef.current, SelectedLayout)
      CyCore.fit()
    }
  }, [CyCore, divRef, SelectedLayout])

  const options = [
    {
      icon: <CachedIcon />,
      action: () => {
        if (CyCore) {
          applyLayout(CyCore.elements(), divRef.current, SelectedLayout)
          CyCore.fit()
        }
      },
      tooltip: 'Apply layout',
    },
    {
      icon: <FilterCenterFocusIcon />,
      action: () => {
        if (CyCore) {
          CyCore.fit()
        }
      },
      tooltip: 'Fit graph',
    },
    {
      icon: DisableZoom ? <ZoomInIcon /> : <ZoomOutIcon />,
      action: () => {
        setDisableZoom(prev => !prev)
      },
      tooltip: DisableZoom ? 'Enable zoom' : 'Disable zoom',
    },
    ...(actions ?? []),
  ]

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {options.map((opt, i) => (
          <Tooltip key={i} title={opt.tooltip}>
            <IconButton color='secondary' onClick={opt.action}>
              {opt.icon}
            </IconButton>
          </Tooltip>
        ))}
        <GraphEditorSelect
          items={LAYOUTS}
          value={SelectedLayout}
          label='Graph layout'
          handleChange={value => setSelecteLayout(value)}
        />
        {enableDepth && (
          <TextField
            label='Depth'
            variant='standard'
            type='number'
            size='small'
            inputProps={{
              min: 1,
            }}
            sx={{ maxWidth: 150, ml: '1rem' }}
            defaultValue={1}
            onChange={({ target: { value } }) => {
              if (onChangeDepth) onChangeDepth(value)
            }}
          />
        )}
        <StyledSearch
          label='Search node'
          variant='standard'
          size='small'
          sx={{ maxWidth: 300, ml: '1rem' }}
          onChange={({ target: { value } }) => {
            if (timeout) clearTimeout(timeout)

            timeout = setTimeout(() => {
              setSearch(value)
            }, 1000)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {Boolean(NodesFound.length) && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color='secondary'
              disabled={!NodeFoundIndex}
              onClick={() => setNodeFoundIndex(prev => prev - 1)}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <Box>
              {NodeFoundIndex + 1} of {NodesFound.length}
            </Box>
            <IconButton
              color='secondary'
              disabled={NodeFoundIndex + 1 === NodesFound.length}
              onClick={() => setNodeFoundIndex(prev => prev + 1)}
            >
              <NavigateNextIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        ref={divRef}
        sx={{ width: '100%', height: 'calc(100% - 65px)' }}
      ></Box>
    </>
  )
}

export default BaseGraph
