import {
  alpha,
  Input,
  InputBase,
  styled,
  Switch,
  Typography,
} from '@mui/material'
import React, { FunctionComponent, PropsWithChildren } from 'react'

const StyledDiv = styled("div")(({ theme }) => ({
  color: theme.customPalette.text,
  display: 'flex',
  maxWidth: 355,
  '&:last-of-type': {
    marginBottom: theme.spacing(1),
  },
  fontSize: 18,
  fontWeight: 400,
  letterSpacing: '0.5px',
  lineHeight: 1.5
}))

const StyledLabel = styled('strong')(() => ({
  margin: 'auto 0',
}))

interface IGraphEditorOption {
  label: string
}

const GraphEditorOption: FunctionComponent<
  PropsWithChildren<IGraphEditorOption>
> = ({ label, children }) => {
  return (
    <StyledDiv>
      <StyledLabel>{label}</StyledLabel>
      {children}
    </StyledDiv>
  )
}

export default GraphEditorOption
