import Grid from '@mui/material/Grid'
import CardOverview from 'Components/Card/CardOverview'
import OverviewGrid from 'Components/Card/OverviewGrid'
import CardDebt from 'Components/Card/CardDebt'
import CardMetrics from 'Components/Card/CardMetrics'


import { getDefaultLine } from '../../lib/line'
import { getDefaultPie } from '../../lib/pie'
import { getDefaultDonut } from '../../lib/donut'

import { TemporalStatistics } from 'Model/arcan-types'

import Diagnostic from '../../img/icons/SystemDiagnostic.svg'
import Metrics from '../../img/icons/QualityMetrics.svg'
import Smells from '../../img/icons/Smell.svg'
import Debt from '../../img/icons/Icon/debt.svg'
import Rigidity from '../../img/icons/Icon/rigidity.svg'
import Design from '../../img/icons/Icon/Design.svg'
import Containers from '../../img/icons/Containers.svg'
import Unity from '../../img/icons/Unity.svg'
import LinesOfCode from '../../img/icons/LinesOfCodes.svg'



interface IOverview {
	projectOverview: TemporalStatistics
	versionId: String 
}

const Overview = ({ projectOverview, versionId }: IOverview) => {
	let graphData: [string, number][] =
		projectOverview?.historical?.techDebtIndex?.map((val) => [
			val?.version?.versionDate,
			parseInt(val.value),
		]) ?? []
	
	const smellNames = new Map<string, string>([
		["cyclicDep", "Cyclic Dependency"],
		["unstableDep", "Unstable Dependency"],
		["hubLikeDep", "Hublike Dependency"],
		["godComponent", "God Component"],
		["wideHierarchy", "Wide Hierarchy"],
		["cyclicHierarchy", "Cyclic Hierarchy"],
		["deepHierarchy","Deep Hierarchy"],
		["", "Unknown Smell"]
	])

	const smellColors = ['#ff3399', '#9D8DF1', '#d3aa41', '#41D3BD', "#493db8"]


	let techDebtSources: {value:number, name: string}[] = projectOverview?.historical?.techDebtSources
		?.filter(s => s.version.versionId === versionId)
		?.map(s =>
			({name: smellNames?.get(s.seriesName)!, value: Number(s.value)})
		)??[]
	const numberOfSmells = new Map<string, number>()
	const nSmellsInVersion = projectOverview?.historical?.numberOfSmells?.filter(s => s.version.versionId === versionId)??[]
	nSmellsInVersion?.forEach(s => {
		if(s.seriesName !== "all") {
			const nSmells = numberOfSmells?.get(s.seriesName)??0
			numberOfSmells?.set(s.seriesName, nSmells + Number(s.value))
		}
	})

	const numOfSmellsData: {value:number, name: string}[] = []
	numberOfSmells?.forEach((value, key) => {
		numOfSmellsData?.push({
			name: smellNames?.get(key)?? "Other",
			value: value
		})
	})
	numOfSmellsData?.sort((a, b) => a?.name?.localeCompare(b.name))
	techDebtSources?.sort((a, b) => a?.name?.localeCompare(b.name))


	return (
		<Grid
			container
			spacing={2}
			sx={{
				width: '98%',
				margin:'5px auto',
				padding: '0 0 1rem 0',
			}}
		>
				<Grid item xs={12} md={12}>
					<div style={{ display: 'flex' }}>
						<img src={Debt} alt={Debt} style={{ marginRight: '10px' }} /><h2 className='title'>Technical debt</h2>
					</div>
				</Grid>
				<Grid item xs={12} md={3} className='step1'>
					
						<CardOverview
							projectCard={{
								title: 'Score',
								um: '%',
								progress: {
									data: projectOverview?.trends?.techDebtScore.value,
									rating: {
										data: projectOverview?.trends?.techDebtRating.value
									}
								}
							}} />
					
				</Grid>

				<Grid item xs={12} md={6} className='step2'>
					
						<OverviewGrid GridOverview={{
								box: {
									title: 'Hours',
									data: projectOverview?.trends?.techDebtHours
								},
								box1: {
									title: 'Index',
									data: projectOverview?.trends?.techDebtIndex
								},
								box2: {
									title: 'Spread',
									data: projectOverview?.trends?.techDebtDispersionMetric
								}
							}} />
							<CardDebt technicalDebt={{
								chart: {
									title: 'Technical debt over time',
									data: getDefaultLine(graphData)
								}
							}} />
				</Grid>

				<Grid item xs={12} md={3} className='step3'>
						<CardOverview
							projectCard={{
								title: 'Technical debt source',
								chart: {
									data: getDefaultPie(techDebtSources, smellColors)
								}
							}} />
				</Grid>

				<Grid item xs={12} md={6}>
					<div style={{ display: 'flex' }}>
						<img src={Smells} alt={Smells} style={{ marginRight: '10px' }} /><h2 className='title'>Architectural Smells</h2>
					</div>
				</Grid>
				<Grid item xs={12} md={3}>
					<div style={{ display: 'flex' }}>
						<img src={Metrics} alt={Metrics} style={{ marginRight: '10px' }} /><h2 className='title'>Quality Metrics</h2>
					</div>
				</Grid>
				<Grid item xs={12} md={2}>
					<div style={{ display: 'flex' }}>
						<img src={Diagnostic} alt={Diagnostic} style={{ marginRight: '10px' }} /><h2 className='title'>System Diagnostic</h2>
					</div>
				</Grid>

				<Grid item xs={12} md={3} className='step4'>
					<CardOverview
							projectCard={{
								title: 'Total Architectural Smells',
								value: projectOverview?.trends?.numberOfSmells
					}} />
				</Grid>
				<Grid item xs={12} md={3} className='step5'>
						<CardOverview
							projectCard={{
								title: 'Architectural Smells By Type',
								chart: {
									data: getDefaultDonut(numOfSmellsData, smellColors)
								}
							}} />
				</Grid>

				<Grid item xs={6} md={3} className='step6'>
								<CardMetrics cardMetrics={{
									data: projectOverview?.trends?.rigidity.value,
									type: {
										icon: {
											src: Rigidity,
											alt: Rigidity
										},
										text: 'Rigidity'
									}
								}} />

							<CardMetrics cardMetrics={{
								data: projectOverview?.trends?.spaghettiDesignRatio.value,
								type: {
									icon: {
										src: Design,
										alt: Design
									},
									text: 'Design Complexity',
								}
							}} />
					
				</Grid>
				<Grid item xs={12} md={3} className='step7'>
						<CardOverview
							projectCard={{
								list: {
									type: {
										name: 'Containers',
										icon: {
											src: Containers,
											alt: Containers,
										},
										data: projectOverview?.trends?.numberOfContainers,
									},
									type1: {
										name: 'Units',
										icon: {
											src: Unity,
											alt: Unity,
										},
										data: projectOverview?.trends?.numberOfUnits,
									},
									type2: {
										name: 'Lines Of Code',
										icon: {
											src: LinesOfCode,
											alt: LinesOfCode,
										},
										data: projectOverview?.trends?.linesOfCode,
									},
								}
							}} />
				</Grid>
			</Grid>
	)
}

export default Overview