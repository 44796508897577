import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import logo from '../../img/ArcanLogoNegative.png'
import illustrazione from '../../img/login/illustrazione.png'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/material'

const StyledGrid = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.customPalette.appBackgroud,
	color: theme.customPalette.text,
	margin: 0,
}))

const Login = () => {
	const [showPassword, setShowPassword] = useState(false)

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		console.log({
			email: data.get('email'),
			password: data.get('password'),
		})
	}

	return (
		<StyledGrid container spacing={2} id='login_container'>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					margin: 'auto 0',
				}}
			>
				<img
					id='login_illustration'
					alt='login illustration'
					src={illustrazione}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					margin: 'auto 0',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: '0 20px',
					}}
				>
					<img src={logo} alt='arcan logo' height={25} />
					<br />
					<Typography component='h1' variant='h5'>
						Welcome back
					</Typography>
					<Box
						component='form'
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin='normal'
							required
							fullWidth
							id='email'
							label='Your email'
							name='email'
							autoComplete='email'
							autoFocus
							variant='standard'
						/>
						<TextField
							margin='normal'
							required
							fullWidth
							name='password'
							label='Your password'
							id='password'
							autoComplete='current-password'
							variant='standard'
							type={showPassword ? 'text' : 'password'}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<FormControlLabel
							control={<Checkbox value='remember' color='primary' />}
							label='Remember me'
						/>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							sx={{ mt: 3, mb: 2 }}
						>
							Log In
						</Button>
						<Grid container>
							<Grid item xs>
								<Link href='#' variant='body2'>
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								<Link href='#' variant='body2'>
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Grid>
		</StyledGrid>
	)
}

export default Login
