import {
	Button,
	Typography,
	styled,
	CircularProgress,
	Container,
	Box,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Input,
	Alert,
	AlertTitle,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import HelpIcon from '@mui/icons-material/Help'

import { SubHeader } from 'Components/Header'
import {
	CustomRadioField,
	CustomSelectField,
	CustomTextField,
	CustomCheckboxField,
} from 'Components/Form'
import { ProgrammingLanguage, ProjectInput, MutationNewProjectUploadArgs, VersionControlSystems } from 'Model/arcan-types'
import { ChangeEvent, useState } from 'react';
import { useSnackbar } from 'Components/Context/SnackbarContext'

const StyledForm = styled(Form)(() => ({
	height: '100%',
}))

const StyledLink = styled('a')(({ theme }) => ({
	color: theme.palette.secondary.main,
}))

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.customPalette.cardBackground,
		color: theme.customPalette.text,
	},
}))

const ContainerForm = styled(Container)(({ theme }) => ({
	padding: '6rem',
	height: 'calc(100% - 64px)',
	maxWidth: '400px',
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	color: theme.customPalette.text,
}))

interface INewProject {
	submitAction: (project: ProjectInput) => void
	submitActionWithUpload: (project: ProjectInput, upload: MutationNewProjectUploadArgs['upload']) => void
	submitting: boolean
	programmingLanguages: {
		value: string
		text: string
	}[]
}

interface IInitValues {
	name: string
	language: ProgrammingLanguage | ''
	type: string
	localPath: string
	projectLink: string
	versionControlSystem: VersionControlSystems
	branch: string
	username: string
	password: string
	requireCredentials: boolean
	fileUpload: File | null
}

const NewProject = ({
	submitAction,
	submitActionWithUpload,
	submitting,
	programmingLanguages,
}: INewProject) => {
	const navigate = useNavigate()
	const [file, setFile] = useState<File>();
	const initValues: IInitValues = {
		name: '',
		language: '',
		type: 'remote',
		localPath: '',
		projectLink: '',
		versionControlSystem: VersionControlSystems.Git,
		branch: '',
		username: '',
		password: '',
		requireCredentials: false,
		fileUpload: null
	}

	function locationInputFields(values: IInitValues): React.ReactNode {
		if(values.type === 'local'){
			return <CustomTextField name='localPath' label='Project path' required />;
		}

		if(values.type === 'remote'){
			if(values.versionControlSystem == VersionControlSystems.NoVcs){
				values.versionControlSystem = VersionControlSystems.Git
			}
			return <>
			<CustomTextField
				name='projectLink'
				label="Remote's URL"
				required
			/>
			<CustomTextField name='branch' label='Branch name' />
			<CustomCheckboxField
				name='requireCredentials'
				label={
					<Box sx={{ display: 'flex', gap: ' 8px' }}>
						Project requires authentication{' '}
						<StyledTooltip
							placement='right'
							title={
								<React.Fragment>
									<Typography>
										For more informations about autheticate Arcan with
										your git project, please read the following
										guides:
										<ul>
											<li>
												<StyledLink
													href='https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token'
													target='_blank'
												>
													GitHub Personal access tokens
												</StyledLink>
											</li>
											<li>
												<StyledLink
													href='https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html'
													target='_blank'
												>
													GitLab Personal access tokens
												</StyledLink>
											</li>
										</ul>
									</Typography>
								</React.Fragment>
							}
						>
							<HelpIcon />
						</StyledTooltip>
					</Box>
				}
			/>
			{values.requireCredentials && (
				<>
					<CustomTextField name='username' label='Username' />
					<CustomTextField
						name='password'
						label='Password/Token'
						type='password'
					/>
				</>
			)}
		</>
		}
		
		if(values.type === 'upload'){
			const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
				if (e.target.files) {
				  setFile(e.target.files[0]);
				  values.fileUpload = e.target.files[0]
				}
			};

			return (
				<>
				<Typography>Upload a zip file containing a folder with the project.</Typography>
				<Input placeholder='Zip file' type='file' onChange={handleFileChange}></Input>
				<CustomTextField name='branch' label='Branch name' />
				</>
			  );
		}
	}

	function showCsharpWarning(values: IInitValues) {
		if(values.language === "CSHARP"){
			return <Alert sx={{color: "white"}} severity="info" variant='outlined'>
				<AlertTitle>Note</AlertTitle>
				General purpose versions of Arcan are not fully compatible with all .NET Core versions.
				<a href='https://docs.arcan.tech'>More information</a>.
				</Alert>
		}
		return <></>
	}

	return (
		<Formik
			initialValues={initValues}
			onSubmit={(value) => {
				if(value.language){
					const projectInput: ProjectInput = {
						name: value.name,
						language: value.language,
						versionControlSystem: value.versionControlSystem,
						projectLocations: [
							{
								location: value.localPath,
								remoteURL: value.projectLink,
								authPassword: value.password,
								authUsername: value.username,
								branch: value.branch,
							},
						],	
					}
					if (value.type === 'upload' && value.fileUpload) {
						submitActionWithUpload(projectInput, value.fileUpload)
					}else{
						submitAction(projectInput)
					}
				}
			}}
		>
			{({ values }) => (
				<StyledForm>
					<SubHeader
						title='New project'
						buttons={[
							{
								text: 'CANCEL',
								variant: 'contained',
								onClick: () => navigate('/'),
							},
						]}
					/>
					<ContainerForm maxWidth='xs'>
						<Typography variant='h1'>Add new project</Typography>
						<Typography variant='subtitle1'>Add basic informations</Typography>
						<CustomTextField
							name='name'
							label='Name'
							required
							validate={(value) => {
								if (!/^\b[a-zA-Z0-9_-]+\b$/i.test(value))
									return 'Project name must only contains letters, numbers and -_.'
								else return null
							}}
						/>
						<CustomSelectField
							name='language'
							label='Language'
							children={programmingLanguages}
						/>
						{ showCsharpWarning(values) }
						<CustomRadioField
							name='type'
							label='Type'
							children={[
								{ value: 'local', text: 'Local project' },
								{ value: 'remote', text: 'Remote project' },
								{ value: 'upload', text: 'Upload project (zip)' },
							]}
						/>
						<CustomSelectField 
							required
							name='versionControlSystem'
							label='Versioniong System'
							defaultValue={VersionControlSystems.Git}
							children={[
								{value: VersionControlSystems.Git, text: "Git"},
								{value: VersionControlSystems.Svn, text: "Subversion"},
								{value: VersionControlSystems.NoVcs, text: "None"},
							]}
						/> 
						{ locationInputFields(values) }
						<Button
							variant='outlined'
							color='secondary'
							type='submit'
							disabled={submitting}
							sx={{
								marginTop: '1rem',
							}}
							size='medium'
						>
							{submitting ? (
								<CircularProgress
									color='inherit'
									size={24}
									sx={{ margin: '0 1.4rem' }}
								/>
							) : (
								'CREATE'
							)}
						</Button>
					</ContainerForm>
				</StyledForm>
			)}
		</Formik>
	)
}

export default NewProject
