import React, { useEffect, useState } from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import WithLoading from 'Components/HOC/WithLoading'
import Assessment from 'Templates/Project/Assessment'
import {
	useGetSmells,
	IGetSmellList,
	useGetSmellGraph,
} from 'Queries/Smells'

const AssessmentWithLoading = WithLoading(Assessment)

const AssessmentData = () => {
	const [SelectedSmellId, setSelectedSmellId] = useState<number>()
	const { projectId, versionId } =
		useParams<{ projectId: string; versionId: string }>()
	const [getSmells, { loading, data }] = useGetSmells()
	const [getSmellGraph, { loading: loadingGraph, data: dataGrap }] =
		useGetSmellGraph()

	const navigate = useNavigate()
	useEffect(() => {
		if (projectId && versionId) {
			getSmells({
				variables: {
					projectId: parseInt(projectId),
					versionId: versionId ?? '',
				},
			})
		}
	}, [projectId, versionId])

	useEffect(() => {
		if (projectId && versionId && SelectedSmellId) {
			getSmellGraph({
				variables: {
					projectId: parseInt(projectId),
					versionId,
					smellId: SelectedSmellId,
				},
			})

			navigate(`selectedsmell/${SelectedSmellId ?? ''}`, {
				replace: false,
				state: { selectedNodeId: SelectedSmellId }
			})
		}
	}, [projectId, versionId, SelectedSmellId])

	return  (
		<AssessmentWithLoading
			loading={loading}
			showHeader={false}
			projectAssessment={(data ? data : {}) as IGetSmellList}
			setSelectedSmellId={setSelectedSmellId}
		/>
	)
}

export default AssessmentData
