import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
  Chip,
  Box,
} from '@mui/material'
import React, { FunctionComponent, useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import moment from 'moment'
import SmellIcon from '../../img/icons/warnigcode.svg'
import Linesicon from '../../img/icons/linecode.svg'
import { BaseChart } from '../Chart'
import { getProjectCardLineGraph } from '../../lib/line'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Project } from 'Model/arcan-types'

const StyledCard = styled(Card)(({ theme }) => ({
  height: 'fit-content',
  color: theme.palette.secondary.main,
  margin: '1rem',
  background: theme.customPalette.cardBackground,
}))

const SubHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.customPalette.title,
  marginRight: '-30px',
}))

const StyleMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: theme.customPalette.text,
}))

const StyledImg = styled('img')(() => ({
  marginRight: '.5rem',
}))

const StyeldCardActions = styled(CardActions)(({ theme }) => ({
  padding: '1rem',
  color: theme.customPalette.text,
}))

const StyledTechDebtTitle = styled(Typography)(({ theme }) => ({
  color: theme.customPalette.title,
  display: 'inline',
  marginLeft: '.5rem',
}))

const StyledCardContent = styled(CardContent)(() => ({
  height: 100,
  padding: '0 16px',
  width: 536,
}))

const ChartConatiner = styled('div')(() => ({
  height: 'calc(100% - 25px)',
}))

const LoadingContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
}))

function getNumberWithOrdinal(n: number) {
  var s = ['th', 'st', 'nd', 'rd']
  var v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

interface IProjectCard {
  project: Project
  loading: boolean
  analyze: (projectId: number, projectName: string) => void
  deleteProject: (projectId: number, projectName: string) => void
  update: (projectId: number) => void
  disabled: boolean
}

const ProjectCard: FunctionComponent<IProjectCard> = ({
  project,
  loading,
  analyze,
  update,
  deleteProject,
  disabled,
}) => {
  const [Open, setOpen] = useState(false)

  const buttonRef = useRef<SVGSVGElement>(null)
  const navigate = useNavigate()

  const lastVersion = project.versions.find(
    v => v.versionIndex === project.lastAnalysedVersion?.versionIndex
  )

  const ratingLabelColor = (x: string) => {
    switch (x) {
      case 'A':
        return 'green'
      case 'B':
        return 'yellow'
      case 'C':
        return 'orange'
      case 'D':
        return '#FBF9FF'
      case 'F':
        return 'red'
      default:
        return 'grey'
    }
  }

  const {
    techDebtScore,
    linesOfCode,
    techDebtIndex,
    techDebtRating,
    numberOfSmells,
    techDebtIndexHistory
  } = project.resultsSummary ?? {}

  const techScore = Number(techDebtScore ?? '0')
  const techScorePercentile = Math.round(techScore * 100)
  const percentile = getNumberWithOrdinal(techScorePercentile)
  const klocs = (Number(linesOfCode ?? '0') / 1000).toFixed(1)
  var graphData: [number, number][] = techDebtIndexHistory?.map((value, index) => [ index, parseInt(value) ]) ?? []

  if (graphData.length === 1) {
    graphData = [...graphData, [1, graphData[0][1]]]
  }

  return (
    <StyledCard>
      <CardHeader
        title={
          <>
            <a
              style={{ cursor: 'pointer', marginRight: '16px' }}
              onClick={() =>
                navigate(
                  `/project/${project.id}/overview/${lastVersion?.versionId}`
                )
              }
            >
              {project.name}
            </a>
            <Chip
              label={project.programmingLanguage}
              variant='outlined'
              size='small'
            />
          </>
        }
        action={
          <>
            <IconButton disabled={disabled} onClick={() => setOpen(true)}>
              <StyleMoreVertIcon ref={buttonRef} />
            </IconButton>
            <Menu
              anchorEl={buttonRef.current}
              open={Open}
              onClose={() => setOpen(false)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                sx={{ fontSize: '14px', width: 120 }}
                onClick={() => {
                  setOpen(false)
                  update(project.id)
                }}
              >
                <EditIcon
                  color='inherit'
                  sx={{ mr: '.5rem', height: 20, width: 20 }}
                />
                Edit
              </MenuItem>
              <MenuItem
                sx={{ fontSize: '14px', width: 120 }}
                onClick={() => {
                  setOpen(false)
                  deleteProject(project.id, project.name)
                }}
              >
                <DeleteIcon
                  color='inherit'
                  sx={{ mr: '.5rem', height: 20, width: 20 }}
                />
                Delete
              </MenuItem>
            </Menu>
          </>
        }
        subheader={
          <SubHeaderContainer>
            <Typography variant='subtitle2'>
              Latest version: {lastVersion?.shortenedVersionId ?? '-'}
            </Typography>
            <Typography variant='subtitle2' sx={{ marginLeft: '1rem' }}>
              Date:{' '}
              {lastVersion
                ? moment(lastVersion.versionDate).format('DD/MM/YYYY')
                : '-'}
            </Typography>
            <Typography variant='subtitle2' sx={{ marginLeft: 'auto' }}>
              TD Index: {techDebtIndex}
            </Typography>
          </SubHeaderContainer>
        }
      />
      <StyledCardContent>
        {loading ? (
          <LoadingContainer>
            <CircularProgress color='secondary' sx={{ margin: 'auto' }} />
          </LoadingContainer>
        ) : (
          <>
            <Typography
              variant='h2'
              sx={{
                display: 'inline',
                color: ratingLabelColor(techDebtRating ?? ''),
              }}
            >
              {techDebtRating ?? ''}
            </Typography>
            <StyledTechDebtTitle variant='subtitle2'>
              TD rating ({percentile} percentile)
            </StyledTechDebtTitle>
            <ChartConatiner>
              <BaseChart options={getProjectCardLineGraph(graphData)} />
            </ChartConatiner>
          </>
        )}
      </StyledCardContent>
      <StyeldCardActions>
        <Box style={{ display: 'flex', gap: '16px' }}>
          <Typography variant='h3'>
            <StyledImg src={SmellIcon} alt='smell-icon' />
            {numberOfSmells} smells
          </Typography>
          <Typography variant='h3'>
            <StyledImg src={Linesicon} alt='lines ofcode-icon' />
            {klocs}K LOC
          </Typography>
        </Box>
        <div style={{ marginRight: '0px', marginLeft: 'auto' }}>
          <Button
            color='secondary'
            variant='outlined'
            sx={{ marginRight: '16px' }}
            onClick={() => analyze(project.id, project.name)}
            disabled={disabled}
          >
            ANALYZE
          </Button>
          <Button
            color='secondary'
            variant='contained'
            onClick={() =>
              navigate(
                `/project/${project.id}/overview/${lastVersion?.versionId}`
              )
            }
          >
            Inspect
          </Button>
        </div>
      </StyeldCardActions>
    </StyledCard>
  )
}

export default ProjectCard
